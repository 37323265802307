* {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;

}

body {
	color: #415674;
	background: white;
	font-weight: 400;
	line-height: 1.82;
	font-size: 16px;
	font-family: "Nunito Sans", sans-serif;
}

a {
	color: #415674;
	cursor: pointer;
	outline: none;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	text-decoration: none;
}

a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none;
}

a:hover {
	color: #00cc83;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 12px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #262d3d;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white h1 a,
.text-white h2 a,
.text-white h3 a,
.text-white h4 a,
.text-white h5 a,
.text-white h6 a {
	color: white;
}

h1 {
	font-size: 65px;
}

h2 {
	font-size: 45px;
}

h3 {
	line-height: 1.35;
	font-size: 30px;
}

h4 {
	line-height: 1.4;
	font-size: 22px;
}

h5 {
	line-height: 1.5;
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
	display: inline-block;
}

header:after,
section:after,
footer:after {
	display: block;
	clear: both;
	content: "";
}

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
	width: 100%;
	height: auto;
	font-size: 18px;
	font-weight: 600;
	border-radius: 5px;
	padding: 16px 30px;
	background: #f7f7f7;
	font-family: "Raleway", sans-serif;
	border: 1px solid #f7f7f7;
}

@media only screen and (max-width: 480px) {

	input,
	select,
	textarea,
	.nice-select,
	.form-control {
		font-size: 16px;
	}
}

.nice-select {
	line-height: 1.5;
}

textarea {
	display: inherit;
}

.form-group {
	margin-bottom: 30px;
}

input:focus,
textarea:focus,
.form-control:focus,
.nice-select.open,
.nice-select:active,
.nice-select:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: transparent;
	border-color: #e9eaeb;
}

button:focus {
	outline: none;
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
	color: #262d3d;
	text-transform: capitalize;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
	color: #262d3d;
	text-transform: capitalize;
}

input::-ms-input-placeholder,
select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
	color: #262d3d;
	text-transform: capitalize;
}

input::placeholder,
select::placeholder,
textarea::placeholder,
.form-control::placeholder {
	color: #262d3d;
	text-transform: capitalize;
}

input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input[type="checkbox"],
input[type="radio"] {
	height: auto;
	width: auto;
}

/*******************************************************/
/*****************  02. Common Classes *****************/
/*******************************************************/
.page-wrapper {
	position: relative;
	z-index: 9;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	min-width: 300px;
	background: #ffffff;
}

.container {
	max-width: 1200px;
	padding-left: 12px;
	padding-right: 15px;
}

.no-gap {
	margin-left: 0;
	margin-right: 0;
}

.no-gap>div {
	padding-left: 0;
	padding-right: 0;
}

.small-gap {
	margin-left: -5px;
	margin-right: -5px;
}

.small-gap>div {
	padding-left: 5px;
	padding-right: 5px;
}

@media only screen and (min-width: 1501px) {
	.large-gap {
		margin-left: -37.5px;
		margin-right: -37.5px;
	}

	.large-gap>div {
		padding-left: 37.5px;
		padding-right: 37.5px;
	}
}

@media only screen and (max-width: 375px) {
	.col-small {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

/** Section Title style **/
.section-title {
	z-index: 1;
	position: relative;
}

.section-title h2 {
	text-transform: capitalize;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.section-title h2 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 767px) {
	.section-title h2 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 575px) {
	.section-title h2 {
		font-size: 30px;
	}
}

.section-title h2 .number {
	color: #204ecf;
}

.section-title h2 span:not(.number) {
	display: inline-block;
	padding: 0 15px 10px 10px;
	margin: 0 -15px -10px -10px;
	/* background: url(../images/shapes/title-bg.png) no-repeat right bottom; */
}

.sub-title {
	color: white;
	font-size: 15px;
	font-weight: 700;
	border-radius: 5px;
	padding: 3px 16px 1px;
	display: inline-block;
	text-transform: uppercase;
	font-family: "Raleway", sans-serif;
	background: #00cc83;
}

.sub-title.style-two {
	color: #204ecf;
	background: rgba(32, 78, 207, 0.1);
}

.sub-title-two {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 20px;
	display: inline-block;
	color: #204ecf;
	font-family: "Raleway", sans-serif;
}

.sub-title-three {
	font-size: 20px;
	font-weight: 700;
	color: #2467ec;
	margin-bottom: 15px;
	display: inline-block;
}

.sub-title-three span {
	position: relative;
}

.sub-title-three span:before {
	content: "";
	width: 100%;
	height: 10px;
	left: 0;
	top: 95%;
	position: absolute;
	/* background: url(../images/shapes/subtitle-line.png) no-repeat center/cover; */
}

.bg-text {
	text-transform: uppercase;
	position: absolute;
	font-size: 355px;
	font-weight: 800;
	line-height: 0.7;
	opacity: 0.04;
	z-index: -1;
	bottom: 0;
	right: 0;
}

@media only screen and (max-width: 1500px) {
	.bg-text {
		font-size: 200px;
	}
}

@media only screen and (max-width: 1199px) {
	.bg-text {
		font-size: 150px;
	}
}

@media only screen and (max-width: 575px) {
	.bg-text {
		font-size: 100px;
	}
}

@media only screen and (max-width: 480px) {
	.bg-text {
		font-size: 75px;
	}
}

/** Button style **/
.theme-btn,
a.theme-btn {
	color: white;
	cursor: pointer;
	font-weight: 800;
	font-size: 15px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 5px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 15px 40px 12px;
	text-transform: uppercase;
}

.theme-btn i,
a.theme-btn i {
	font-size: 12px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-left: 15px;
}

.theme-btn:hover i,
a.theme-btn:hover i {
	margin: 0 -3px 0 18px;
}

.theme-btn:hover,
.theme-btn:focus,
.theme-btn.style-two:not(:hover),
a.theme-btn:hover,
a.theme-btn:focus,
a.theme-btn.style-two:not(:hover) {
	background: #204ecf;
}

.theme-btn:not(:hover),
.theme-btn.style-two:hover,
a.theme-btn:not(:hover),
a.theme-btn.style-two:hover {
	background: #007dc6;
}

.theme-btn.style-three,
a.theme-btn.style-three {
	color: #262d3d;
	background: transparent;
	border: 1px solid #e9eaeb;
}

.theme-btn.style-three:hover,
a.theme-btn.style-three:hover {
	color: #204ecf;
	border-color: #204ecf;
}

.theme-btn.style-four,
a.theme-btn.style-four {
	color: #262d3d;
	background: #ffb113;
}

.theme-btn.style-four:hover,
a.theme-btn.style-four:hover {
	color: white;
	background: #204ecf;
}

.theme-btn.style-five,
a.theme-btn.style-five {
	color: #262d3d;
	background: #f7f7f7;
}

.theme-btn.style-five:hover,
a.theme-btn.style-five:hover {
	color: white;
	background: #ffb113;
}

@media only screen and (max-width: 480px) {

	.theme-btn,
	a.theme-btn {
		padding: 12px 20px;
	}
}

/* Read More Btb */
.read-more {
	font-size: 15px;
	font-weight: 800;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	text-transform: uppercase;
	text-decoration: underline;
}

.read-more.color-two {
	color: #204ecf;
}

.read-more i {
	float: right;
	font-size: 13px;
	margin-left: 10px;
}

/* List style One */
.list-style-one li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 18px;
	font-weight: 700;
	font-family: "Raleway", sans-serif;
}

.list-style-one li:before {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: white;
	font-size: 11px;
	margin-top: 4px;
	content: "\f00c";
	margin-right: 15px;
	width: 25px;
	height: 25px;
	background: #00cc83;
	line-height: 25px;
	border-radius: 50%;
	text-align: center;
	font-family: "Font Awesome 5 Free";
}

.list-style-one li:not(:last-child) {
	margin-bottom: 15px;
}

/* List style Two */
.list-style-two li:not(:last-child) {
	margin-bottom: 8px;
}

.list-style-two li:before {

	margin-right: 20px;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

/* List style Three */
.list-style-three li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.list-style-three li:before {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: white;
	margin-top: 4px;
	content: "\f00c";
	font-weight: 600;
	margin-right: 20px;
	width: 35px;
	height: 35px;
	background: #204ecf;
	line-height: 35px;
	border-radius: 50%;
	text-align: center;
	font-family: "Font Awesome 5 Free";
}

.list-style-three li:not(:last-child) {
	margin-bottom: 10px;
}

.list-style-three li h5 {
	margin-bottom: 5px;
}

/* List style Four */
.list-style-four li {
	font-size: 18px;
	font-weight: 700;
	color: #262d3d;
}

@media only screen and (max-width: 375px) {
	.list-style-four li {
		font-size: 16px;
	}
}

.list-style-four li:not(:last-child) {
	margin-bottom: 8px;
}

.list-style-four li:before {
	content: "\f00c";
	margin-right: 10px;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

/** Social Style One **/
.social-style-one {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.social-style-one a {
	color: white;
	font-size: 14px;
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.1);
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
}

.social-style-one a:hover {
	background: #204ecf;
}

.social-style-one a:not(:last-child) {
	margin-right: 7px;
}

/** Social Style two **/
.social-style-two {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-style-two a {
	opacity: 0.5;
	margin: 0 10px;
}

.social-style-two a:hover {
	opacity: 1;
}

/*** Preloader style ** */
.preloader {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background: white;
}

.preloader:after {
	content: "";
	width: 50px;
	height: 50px;
	padding: 0px;
	border: 2px solid;
	border-radius: 100%;
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	border-top-color: rgba(0, 204, 131, 0.65);
	border-right-color: rgba(0, 204, 131, 0.15);
	border-bottom-color: rgba(32, 78, 207, 0.65);
	border-left-color: rgba(32, 78, 207, 0.15);
	-webkit-animation: preloader 0.8s linear infinite;
	animation: preloader 0.8s linear infinite;
}

/* Slick Dots */
.slick-dots {
	margin-top: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.slick-dots li {
	cursor: pointer;
	overflow: hidden;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	width: 22px;
	height: 22px;
	background: transparent;
	line-height: 22px;
	border-radius: 50%;
	text-align: center;
	border: 1px solid transparent;
}

.slick-dots li button {
	text-indent: 100px;
	background: transparent;
}

.slick-dots li:before {
	content: "";
	width: 5px;
	height: 5px;
	left: 50%;
	top: 50%;
	background: #232323;
	position: absolute;
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.slick-dots li.slick-active {
	border-color: #204ecf;
}

.slick-dots li.slick-active:before {
	background: #204ecf;
}

/* Pagination */
.pagination {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.pagination li {
	margin: 10px 5px 0;
}

.pagination li a,
.pagination li .page-link {
	padding: 0;
	border: none;
	font-size: 18px;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-weight: 600;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
	width: 50px;
	height: 50px;
	background: rgba(32, 78, 207, 0.1);
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
}

@media only screen and (max-width: 480px) {

	.pagination li a,
	.pagination li .page-link {
		width: 40px;
		height: 40px;
		font-size: 14px;
		line-height: 40px;
	}
}

.pagination li.disabled .page-link,
.pagination li:last-child .page-link,
.pagination li:first-child .page-link {
	border-radius: 50%;
	background: rgba(32, 78, 207, 0.1);
}

.pagination li:first-child {
	margin-left: 0;
}

.pagination li:last-child {
	margin-right: 0;
}

.pagination li.active .page-link,
.pagination li:hover:not(.disabled) .page-link {
	color: white;
	background: #00cc83;
}

.pagination.style-two li.active .page-link,
.pagination.style-two li:hover:not(.disabled) .page-link {
	background: #204ecf;
}

/*** Scroll Top style ***/
.scroll-top {
	position: absolute;
	z-index: 99;
	color: white;
	top: -27.5px;
	display: none;
	right: calc(50% - 27.5px);
	border: 6px solid #081a55;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	width: 55px;
	height: 55px;
	background: #00cc83;
	line-height: 55px;
	border-radius: 50%;
	text-align: center;
	line-height: 45px;
}

/* Text White */
/* .text-white *,
.text-white .bg-text {
  color: white;
} */

/* Position */
.rel {
	position: relative;
}

.z-0 {
	z-index: 0;
}

.z-1 {
	z-index: 1;
}

.z-2 {
	z-index: 2;
}

.z-3 {
	z-index: 3;
}

.z-4 {
	z-index: 4;
}

.z-5 {
	z-index: 5;
}

.overlay {
	z-index: 1;
	position: relative;
}

.overlay::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.3;
	background-color: black;
}

/* Shapes */
.circle-one,
.circle-two,
.circle-dots,
.rectangle-dots {
	z-index: -1;
	max-width: 20%;
	position: absolute;
}

.rectangle-dots {
	bottom: 20%;
	left: 5%;
	-webkit-animation: 8s down-up-three infinite;
	animation: 8s down-up-three infinite;
}

.circle-dots {
	top: 25%;
	right: 8%;
	-webkit-animation: 8s down-up-two infinite;
	animation: 8s down-up-two infinite;
}

.circle-one {
	top: 20%;
	left: 25%;
	-webkit-animation: 50s shapeAnimationOne infinite;
	animation: 50s shapeAnimationOne infinite;
}

.circle-two {
	top: 25%;
	right: 25%;
	-webkit-animation: 50s shapeAnimationTwo infinite;
	animation: 50s shapeAnimationTwo infinite;
}

/* Backgruond Size */
.bgs-cover {
	background-size: cover;
	background-position: center;
}

/* Color + Background */
.bg-lighter {
	background-color: #f7f7f7;
}

.bg-light-two {
	background-color: #f4f6f9;
}

.bg-green {
	background-color: #007dc6;
}

.bg-blue {
	background-color: #0f256e;
}

.bg-dark-blue {
	background-color: #081a55;
}

.bg-light-blue {
	background-color: #204ecf;
}

.bg-black {
	background-color: #141517;
}

/* Border Radius */
.br-5 {
	border-radius: 5px;
}

.br-10 {
	border-radius: 10px;
}

.br-15 {
	border-radius: 15px;
}

.br-20 {
	border-radius: 20px;
}

.br-25 {
	border-radius: 25px;
}

.br-30 {
	border-radius: 30px;
}

@media only screen and (min-width: 1501px) {
	.home-one .section-title h2 {
		font-size: 48px;
	}
}

/*******************************************************/
/******************* 03. Repeat Style ******************/
/*******************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.bg-text,
.coach-filter li,
.newsletter-radios {
	font-weight: 700;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
}

/*******************************************************/
/************** 04. Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px;
}

.p-15 {
	padding: 15px;
}

.p-20 {
	padding: 20px;
}

.p-25 {
	padding: 25px;
}

.p-30 {
	padding: 30px;
}

.p-35 {
	padding: 35px;
}

.p-40 {
	padding: 40px;
}

.p-45 {
	padding: 45px;
}

.p-50 {
	padding: 50px;
}

.p-55 {
	padding: 55px;
}

.p-60 {
	padding: 60px;
}

.p-65 {
	padding: 65px;
}

.p-70 {
	padding: 70px;
}

.p-75 {
	padding: 75px;
}

.p-80 {
	padding: 80px;
}

.p-85 {
	padding: 85px;
}

.p-90 {
	padding: 90px;
}

.p-95 {
	padding: 95px;
}

.p-100 {
	padding: 100px;
}

.p-105 {
	padding: 105px;
}

.p-110 {
	padding: 110px;
}

.p-115 {
	padding: 115px;
}

.p-120 {
	padding: 120px;
}

.p-125 {
	padding: 125px;
}

.p-130 {
	padding: 130px;
}

.p-135 {
	padding: 135px;
}

.p-140 {
	padding: 140px;
}

.p-145 {
	padding: 145px;
}

.p-150 {
	padding: 150px;
}

.p-155 {
	padding: 155px;
}

.p-160 {
	padding: 160px;
}

.p-165 {
	padding: 165px;
}

.p-170 {
	padding: 170px;
}

.p-175 {
	padding: 175px;
}

.p-180 {
	padding: 180px;
}

.p-185 {
	padding: 185px;
}

.p-190 {
	padding: 190px;
}

.p-195 {
	padding: 195px;
}

.p-200 {
	padding: 200px;
}

.p-205 {
	padding: 205px;
}

.p-210 {
	padding: 210px;
}

.p-215 {
	padding: 215px;
}

.p-220 {
	padding: 220px;
}

.p-225 {
	padding: 225px;
}

.p-230 {
	padding: 230px;
}

.p-235 {
	padding: 235px;
}

.p-240 {
	padding: 240px;
}

.p-245 {
	padding: 245px;
}

.p-250 {
	padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
	padding-top: 5px !important;
}

.pt-10,
.py-10 {
	padding-top: 10px;
}

.pt-15,
.py-15 {
	padding-top: 15px;
}

.pt-20,
.py-20 {
	padding-top: 20px;
}

.pt-25,
.py-25 {
	padding-top: 25px;
}

.pt-30,
.py-30 {
	padding-top: 30px;
}

.pt-35,
.py-35 {
	padding-top: 35px;
}

.pt-40,
.py-40 {
	padding-top: 40px;
}

.pt-45,
.py-45 {
	padding-top: 45px;
}

.pt-50,
.py-50 {
	padding-top: 50px;
}

.pt-55,
.py-55 {
	padding-top: 55px;
}

.pt-60,
.py-60 {
	padding-top: 60px;
}

.pt-65,
.py-65 {
	padding-top: 65px;
}

.pt-70,
.py-70 {
	padding-top: 70px;
}

.pt-75,
.py-75 {
	padding-top: 75px;
}

.pt-80,
.py-80 {
	padding-top: 80px;
}

.pt-85,
.py-85 {
	padding-top: 85px;
}

.pt-90,
.py-90 {
	padding-top: 90px;
}

.pt-95,
.py-95 {
	padding-top: 95px;
}

.pt-100,
.py-100 {
	padding-top: 100px;
}

.pt-105,
.py-105 {
	padding-top: 105px;
}

.pt-110,
.py-110 {
	padding-top: 110px;
}

.pt-115,
.py-115 {
	padding-top: 115px;
}

.pt-120,
.py-120 {
	padding-top: 120px;
}

.pt-125,
.py-125 {
	padding-top: 125px;
}

.pt-130,
.py-130 {
	padding-top: 130px;
}

.pt-135,
.py-135 {
	padding-top: 135px;
}

.pt-140,
.py-140 {
	padding-top: 140px;
}

.pt-145,
.py-145 {
	padding-top: 145px;
}

.pt-150,
.py-150 {
	padding-top: 150px;
}

.pt-155,
.py-155 {
	padding-top: 155px;
}

.pt-160,
.py-160 {
	padding-top: 160px;
}

.pt-165,
.py-165 {
	padding-top: 165px;
}

.pt-170,
.py-170 {
	padding-top: 170px;
}

.pt-175,
.py-175 {
	padding-top: 175px;
}

.pt-180,
.py-180 {
	padding-top: 180px;
}

.pt-185,
.py-185 {
	padding-top: 185px;
}

.pt-190,
.py-190 {
	padding-top: 190px;
}

.pt-195,
.py-195 {
	padding-top: 195px;
}

.pt-200,
.py-200 {
	padding-top: 200px;
}

.pt-205,
.py-205 {
	padding-top: 205px;
}

.pt-210,
.py-210 {
	padding-top: 210px;
}

.pt-215,
.py-215 {
	padding-top: 215px;
}

.pt-220,
.py-220 {
	padding-top: 220px;
}

.pt-225,
.py-225 {
	padding-top: 225px;
}

.pt-230,
.py-230 {
	padding-top: 230px;
}

.pt-235,
.py-235 {
	padding-top: 235px;
}

.pt-240,
.py-240 {
	padding-top: 240px;
}

.pt-245,
.py-245 {
	padding-top: 245px;
}

.pt-250,
.py-250 {
	padding-top: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
	padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
	padding-bottom: 10px;
}

.pb-15,
.py-15 {
	padding-bottom: 15px;
}

.pb-20,
.py-20 {
	padding-bottom: 20px;
}

.pb-25,
.py-25 {
	padding-bottom: 25px;
}

.pb-30,
.py-30 {
	padding-bottom: 30px;
}

.pb-35,
.py-35 {
	padding-bottom: 35px;
}

.pb-40,
.py-40 {
	padding-bottom: 40px;
}

.pb-45,
.py-45 {
	padding-bottom: 45px;
}

.pb-50,
.py-50 {
	padding-bottom: 50px;
}

.pb-55,
.py-55 {
	padding-bottom: 55px;
}

.pb-60,
.py-60 {
	padding-bottom: 60px;
}

.pb-65,
.py-65 {
	padding-bottom: 65px;
}

.pb-70,
.py-70 {
	padding-bottom: 70px;
}

.pb-75,
.py-75 {
	padding-bottom: 75px;
}

.pb-80,
.py-80 {
	padding-bottom: 80px;
}

.pb-85,
.py-85 {
	padding-bottom: 85px;
}

.pb-90,
.py-90 {
	padding-bottom: 90px;
}

.pb-95,
.py-95 {
	padding-bottom: 95px;
}

.pb-100,
.py-100 {
	padding-bottom: 100px;
}

.pb-105,
.py-105 {
	padding-bottom: 105px;
}

.pb-110,
.py-110 {
	padding-bottom: 110px;
}

.pb-115,
.py-115 {
	padding-bottom: 115px;
}

.pb-120,
.py-120 {
	padding-bottom: 120px;
}

.pb-125,
.py-125 {
	padding-bottom: 125px;
}

.pb-130,
.py-130 {
	padding-bottom: 130px;
}

.pb-135,
.py-135 {
	padding-bottom: 135px;
}

.pb-140,
.py-140 {
	padding-bottom: 140px;
}

.pb-145,
.py-145 {
	padding-bottom: 145px;
}

.pb-150,
.py-150 {
	padding-bottom: 150px;
}

.pb-155,
.py-155 {
	padding-bottom: 155px;
}

.pb-160,
.py-160 {
	padding-bottom: 160px;
}

.pb-165,
.py-165 {
	padding-bottom: 165px;
}

.pb-170,
.py-170 {
	padding-bottom: 170px;
}

.pb-175,
.py-175 {
	padding-bottom: 175px;
}

.pb-180,
.py-180 {
	padding-bottom: 180px;
}

.pb-185,
.py-185 {
	padding-bottom: 185px;
}

.pb-190,
.py-190 {
	padding-bottom: 190px;
}

.pb-195,
.py-195 {
	padding-bottom: 195px;
}

.pb-200,
.py-200 {
	padding-bottom: 200px;
}

.pb-205,
.py-205 {
	padding-bottom: 205px;
}

.pb-210,
.py-210 {
	padding-bottom: 210px;
}

.pb-215,
.py-215 {
	padding-bottom: 215px;
}

.pb-220,
.py-220 {
	padding-bottom: 220px;
}

.pb-225,
.py-225 {
	padding-bottom: 225px;
}

.pb-230,
.py-230 {
	padding-bottom: 230px;
}

.pb-235,
.py-235 {
	padding-bottom: 235px;
}

.pb-240,
.py-240 {
	padding-bottom: 240px;
}

.pb-245,
.py-245 {
	padding-bottom: 245px;
}

.pb-250,
.py-250 {
	padding-bottom: 250px;
}

/* Margin Around */
.m-5 {
	margin: 5px !important;
}

.m-10 {
	margin: 10px;
}

.m-15 {
	margin: 15px;
}

.m-20 {
	margin: 20px;
}

.m-25 {
	margin: 25px;
}

.m-30 {
	margin: 30px;
}

.m-35 {
	margin: 35px;
}

.m-40 {
	margin: 40px;
}

.m-45 {
	margin: 45px;
}

.m-50 {
	margin: 50px;
}

.m-55 {
	margin: 55px;
}

.m-60 {
	margin: 60px;
}

.m-65 {
	margin: 65px;
}

.m-70 {
	margin: 70px;
}

.m-75 {
	margin: 75px;
}

.m-80 {
	margin: 80px;
}

.m-85 {
	margin: 85px;
}

.m-90 {
	margin: 90px;
}

.m-95 {
	margin: 95px;
}

.m-100 {
	margin: 100px;
}

.m-105 {
	margin: 105px;
}

.m-110 {
	margin: 110px;
}

.m-115 {
	margin: 115px;
}

.m-120 {
	margin: 120px;
}

.m-125 {
	margin: 125px;
}

.m-130 {
	margin: 130px;
}

.m-135 {
	margin: 135px;
}

.m-140 {
	margin: 140px;
}

.m-145 {
	margin: 145px;
}

.m-150 {
	margin: 150px;
}

.m-155 {
	margin: 155px;
}

.m-160 {
	margin: 160px;
}

.m-165 {
	margin: 165px;
}

.m-170 {
	margin: 170px;
}

.m-175 {
	margin: 175px;
}

.m-180 {
	margin: 180px;
}

.m-185 {
	margin: 185px;
}

.m-190 {
	margin: 190px;
}

.m-195 {
	margin: 195px;
}

.m-200 {
	margin: 200px;
}

.m-205 {
	margin: 205px;
}

.m-210 {
	margin: 210px;
}

.m-215 {
	margin: 215px;
}

.m-220 {
	margin: 220px;
}

.m-225 {
	margin: 225px;
}

.m-230 {
	margin: 230px;
}

.m-235 {
	margin: 235px;
}

.m-240 {
	margin: 240px;
}

.m-245 {
	margin: 245px;
}

.m-250 {
	margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
	margin-top: 5px !important;
}

.mt-10,
.my-10 {
	margin-top: 10px;
}

.mt-15,
.my-15 {
	margin-top: 15px;
}

.mt-20,
.my-20 {
	margin-top: 20px;
}

.mt-25,
.my-25 {
	margin-top: 25px;
}

.mt-30,
.my-30 {
	margin-top: 30px;
}

.mt-35,
.my-35 {
	margin-top: 35px;
}

.mt-40,
.my-40 {
	margin-top: 40px;
}

.mt-45,
.my-45 {
	margin-top: 45px;
}

.mt-50,
.my-50 {
	margin-top: 50px;
}

.mt-55,
.my-55 {
	margin-top: 55px;
}

.mt-60,
.my-60 {
	margin-top: 60px;
}

.mt-65,
.my-65 {
	margin-top: 65px;
}

.mt-70,
.my-70 {
	margin-top: 70px;
}

.mt-75,
.my-75 {
	margin-top: 75px;
}

.mt-80,
.my-80 {
	margin-top: 80px;
}

.mt-85,
.my-85 {
	margin-top: 85px;
}

.mt-90,
.my-90 {
	margin-top: 90px;
}

.mt-95,
.my-95 {
	margin-top: 95px;
}

.mt-100,
.my-100 {
	margin-top: 100px;
}

.mt-105,
.my-105 {
	margin-top: 105px;
}

.mt-110,
.my-110 {
	margin-top: 110px;
}

.mt-115,
.my-115 {
	margin-top: 115px;
}

.mt-120,
.my-120 {
	margin-top: 120px;
}

.mt-125,
.my-125 {
	margin-top: 125px;
}

.mt-130,
.my-130 {
	margin-top: 130px;
}

.mt-135,
.my-135 {
	margin-top: 135px;
}

.mt-140,
.my-140 {
	margin-top: 140px;
}

.mt-145,
.my-145 {
	margin-top: 145px;
}

.mt-150,
.my-150 {
	margin-top: 150px;
}

.mt-155,
.my-155 {
	margin-top: 155px;
}

.mt-160,
.my-160 {
	margin-top: 160px;
}

.mt-165,
.my-165 {
	margin-top: 165px;
}

.mt-170,
.my-170 {
	margin-top: 170px;
}

.mt-175,
.my-175 {
	margin-top: 175px;
}

.mt-180,
.my-180 {
	margin-top: 180px;
}

.mt-185,
.my-185 {
	margin-top: 185px;
}

.mt-190,
.my-190 {
	margin-top: 190px;
}

.mt-195,
.my-195 {
	margin-top: 195px;
}

.mt-200,
.my-200 {
	margin-top: 200px;
}

.mt-205,
.my-205 {
	margin-top: 205px;
}

.mt-210,
.my-210 {
	margin-top: 210px;
}

.mt-215,
.my-215 {
	margin-top: 215px;
}

.mt-220,
.my-220 {
	margin-top: 220px;
}

.mt-225,
.my-225 {
	margin-top: 225px;
}

.mt-230,
.my-230 {
	margin-top: 230px;
}

.mt-235,
.my-235 {
	margin-top: 235px;
}

.mt-240,
.my-240 {
	margin-top: 240px;
}

.mt-245,
.my-245 {
	margin-top: 245px;
}

.mt-250,
.my-250 {
	margin-top: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
	margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
	margin-bottom: 10px;
}

.mb-15,
.my-15 {
	margin-bottom: 15px;
}

.mb-20,
.my-20 {
	margin-bottom: 20px;
}

.mb-25,
.my-25 {
	margin-bottom: 25px;
}

.mb-30,
.my-30 {
	margin-bottom: 30px;
}

.mb-35,
.my-35 {
	margin-bottom: 35px;
}

.mb-40,
.my-40 {
	margin-bottom: 40px;
}

.mb-45,
.my-45 {
	margin-bottom: 45px;
}

.mb-50,
.my-50 {
	margin-bottom: 50px;
}

.mb-55,
.my-55 {
	margin-bottom: 55px;
}

.mb-60,
.my-60 {
	margin-bottom: 60px;
}

.mb-65,
.my-65 {
	margin-bottom: 65px;
}

.mb-70,
.my-70 {
	margin-bottom: 70px;
}

.mb-75,
.my-75 {
	margin-bottom: 75px;
}

.mb-80,
.my-80 {
	margin-bottom: 80px;
}

.mb-85,
.my-85 {
	margin-bottom: 85px;
}

.mb-90,
.my-90 {
	margin-bottom: 90px;
}

.mb-95,
.my-95 {
	margin-bottom: 95px;
}

.mb-100,
.my-100 {
	margin-bottom: 100px;
}

.mb-105,
.my-105 {
	margin-bottom: 105px;
}

.mb-110,
.my-110 {
	margin-bottom: 110px;
}

.mb-115,
.my-115 {
	margin-bottom: 115px;
}

.mb-120,
.my-120 {
	margin-bottom: 120px;
}

.mb-125,
.my-125 {
	margin-bottom: 125px;
}

.mb-130,
.my-130 {
	margin-bottom: 130px;
}

.mb-135,
.my-135 {
	margin-bottom: 135px;
}

.mb-140,
.my-140 {
	margin-bottom: 140px;
}

.mb-145,
.my-145 {
	margin-bottom: 145px;
}

.mb-150,
.my-150 {
	margin-bottom: 150px;
}

.mb-155,
.my-155 {
	margin-bottom: 155px;
}

.mb-160,
.my-160 {
	margin-bottom: 160px;
}

.mb-165,
.my-165 {
	margin-bottom: 165px;
}

.mb-170,
.my-170 {
	margin-bottom: 170px;
}

.mb-175,
.my-175 {
	margin-bottom: 175px;
}

.mb-180,
.my-180 {
	margin-bottom: 180px;
}

.mb-185,
.my-185 {
	margin-bottom: 185px;
}

.mb-190,
.my-190 {
	margin-bottom: 190px;
}

.mb-195,
.my-195 {
	margin-bottom: 195px;
}

.mb-200,
.my-200 {
	margin-bottom: 200px;
}

.mb-205,
.my-205 {
	margin-bottom: 205px;
}

.mb-210,
.my-210 {
	margin-bottom: 210px;
}

.mb-215,
.my-215 {
	margin-bottom: 215px;
}

.mb-220,
.my-220 {
	margin-bottom: 220px;
}

.mb-225,
.my-225 {
	margin-bottom: 225px;
}

.mb-230,
.my-230 {
	margin-bottom: 230px;
}

.mb-235,
.my-235 {
	margin-bottom: 235px;
}

.mb-240,
.my-240 {
	margin-bottom: 240px;
}

.mb-245,
.my-245 {
	margin-bottom: 245px;
}

.mb-250,
.my-250 {
	margin-bottom: 250px;
}

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {

	/* Padding Around */
	.rp-0 {
		padding: 0px !important;
	}

	.rp-5 {
		padding: 5px !important;
	}

	.rp-10 {
		padding: 10px;
	}

	.rp-15 {
		padding: 15px;
	}

	.rp-20 {
		padding: 20px;
	}

	.rp-25 {
		padding: 25px;
	}

	.rp-30 {
		padding: 30px;
	}

	.rp-35 {
		padding: 35px;
	}

	.rp-40 {
		padding: 40px;
	}

	.rp-45 {
		padding: 45px;
	}

	.rp-50 {
		padding: 50px;
	}

	.rp-55 {
		padding: 55px;
	}

	.rp-60 {
		padding: 60px;
	}

	.rp-65 {
		padding: 65px;
	}

	.rp-70 {
		padding: 70px;
	}

	.rp-75 {
		padding: 75px;
	}

	.rp-80 {
		padding: 80px;
	}

	.rp-85 {
		padding: 85px;
	}

	.rp-90 {
		padding: 90px;
	}

	.rp-95 {
		padding: 95px;
	}

	.rp-100 {
		padding: 100px;
	}

	.rp-105 {
		padding: 105px;
	}

	.rp-110 {
		padding: 110px;
	}

	.rp-115 {
		padding: 115px;
	}

	.rp-120 {
		padding: 120px;
	}

	.rp-125 {
		padding: 125px;
	}

	.rp-130 {
		padding: 130px;
	}

	.rp-135 {
		padding: 135px;
	}

	.rp-140 {
		padding: 140px;
	}

	.rp-145 {
		padding: 145px;
	}

	.rp-150 {
		padding: 150px;
	}

	/* Padding Top */
	.rpt-0,
	.rpy-0 {
		padding-top: 0px !important;
	}

	.rpt-5,
	.rpy-5 {
		padding-top: 5px !important;
	}

	.rpt-10,
	.rpy-10 {
		padding-top: 10px;
	}

	.rpt-15,
	.rpy-15 {
		padding-top: 15px;
	}

	.rpt-20,
	.rpy-20 {
		padding-top: 20px;
	}

	.rpt-25,
	.rpy-25 {
		padding-top: 25px;
	}

	.rpt-30,
	.rpy-30 {
		padding-top: 30px;
	}

	.rpt-35,
	.rpy-35 {
		padding-top: 35px;
	}

	.rpt-40,
	.rpy-40 {
		padding-top: 40px;
	}

	.rpt-45,
	.rpy-45 {
		padding-top: 45px;
	}

	.rpt-50,
	.rpy-50 {
		padding-top: 50px;
	}

	.rpt-55,
	.rpy-55 {
		padding-top: 55px;
	}

	.rpt-60,
	.rpy-60 {
		padding-top: 60px;
	}

	.rpt-65,
	.rpy-65 {
		padding-top: 65px;
	}

	.rpt-70,
	.rpy-70 {
		padding-top: 70px;
	}

	.rpt-75,
	.rpy-75 {
		padding-top: 75px;
	}

	.rpt-80,
	.rpy-80 {
		padding-top: 80px;
	}

	.rpt-85,
	.rpy-85 {
		padding-top: 85px;
	}

	.rpt-90,
	.rpy-90 {
		padding-top: 90px;
	}

	.rpt-95,
	.rpy-95 {
		padding-top: 95px;
	}

	.rpt-100,
	.rpy-100 {
		padding-top: 100px;
	}

	.rpt-105,
	.rpy-105 {
		padding-top: 105px;
	}

	.rpt-110,
	.rpy-110 {
		padding-top: 110px;
	}

	.rpt-115,
	.rpy-115 {
		padding-top: 115px;
	}

	.rpt-120,
	.rpy-120 {
		padding-top: 120px;
	}

	.rpt-125,
	.rpy-125 {
		padding-top: 125px;
	}

	.rpt-130,
	.rpy-130 {
		padding-top: 130px;
	}

	.rpt-135,
	.rpy-135 {
		padding-top: 135px;
	}

	.rpt-140,
	.rpy-140 {
		padding-top: 140px;
	}

	.rpt-145,
	.rpy-145 {
		padding-top: 145px;
	}

	.rpt-150,
	.rpy-150 {
		padding-top: 150px;
	}

	/* Padding Bottom */
	.rpb-0,
	.rpy-0 {
		padding-bottom: 0px !important;
	}

	.rpb-5,
	.rpy-5 {
		padding-bottom: 5px !important;
	}

	.rpb-10,
	.rpy-10 {
		padding-bottom: 10px;
	}

	.rpb-15,
	.rpy-15 {
		padding-bottom: 15px;
	}

	.rpb-20,
	.rpy-20 {
		padding-bottom: 20px;
	}

	.rpb-25,
	.rpy-25 {
		padding-bottom: 25px;
	}

	.rpb-30,
	.rpy-30 {
		padding-bottom: 30px;
	}

	.rpb-35,
	.rpy-35 {
		padding-bottom: 35px;
	}

	.rpb-40,
	.rpy-40 {
		padding-bottom: 40px;
	}

	.rpb-45,
	.rpy-45 {
		padding-bottom: 45px;
	}

	.rpb-50,
	.rpy-50 {
		padding-bottom: 50px;
	}

	.rpb-55,
	.rpy-55 {
		padding-bottom: 55px;
	}

	.rpb-60,
	.rpy-60 {
		padding-bottom: 60px;
	}

	.rpb-65,
	.rpy-65 {
		padding-bottom: 65px;
	}

	.rpb-70,
	.rpy-70 {
		padding-bottom: 70px;
	}

	.rpb-75,
	.rpy-75 {
		padding-bottom: 75px;
	}

	.rpb-80,
	.rpy-80 {
		padding-bottom: 80px;
	}

	.rpb-85,
	.rpy-85 {
		padding-bottom: 85px;
	}

	.rpb-90,
	.rpy-90 {
		padding-bottom: 90px;
	}

	.rpb-95,
	.rpy-95 {
		padding-bottom: 95px;
	}

	.rpb-100,
	.rpy-100 {
		padding-bottom: 100px;
	}

	.rpb-105,
	.rpy-105 {
		padding-bottom: 105px;
	}

	.rpb-110,
	.rpy-110 {
		padding-bottom: 110px;
	}

	.rpb-115,
	.rpy-115 {
		padding-bottom: 115px;
	}

	.rpb-120,
	.rpy-120 {
		padding-bottom: 120px;
	}

	.rpb-125,
	.rpy-125 {
		padding-bottom: 125px;
	}

	.rpb-130,
	.rpy-130 {
		padding-bottom: 130px;
	}

	.rpb-135,
	.rpy-135 {
		padding-bottom: 135px;
	}

	.rpb-140,
	.rpy-140 {
		padding-bottom: 140px;
	}

	.rpb-145,
	.rpy-145 {
		padding-bottom: 145px;
	}

	.rpb-150,
	.rpy-150 {
		padding-bottom: 150px;
	}

	/* Margin Around */
	.rm-0 {
		margin: 0px !important;
	}

	.rm-5 {
		margin: 5px !important;
	}

	.rm-10 {
		margin: 10px;
	}

	.rm-15 {
		margin: 15px;
	}

	.rm-20 {
		margin: 20px;
	}

	.rm-25 {
		margin: 25px;
	}

	.rm-30 {
		margin: 30px;
	}

	.rm-35 {
		margin: 35px;
	}

	.rm-40 {
		margin: 40px;
	}

	.rm-45 {
		margin: 45px;
	}

	.rm-50 {
		margin: 50px;
	}

	.rm-55 {
		margin: 55px;
	}

	.rm-60 {
		margin: 60px;
	}

	.rm-65 {
		margin: 65px;
	}

	.rm-70 {
		margin: 70px;
	}

	.rm-75 {
		margin: 75px;
	}

	.rm-80 {
		margin: 80px;
	}

	.rm-85 {
		margin: 85px;
	}

	.rm-90 {
		margin: 90px;
	}

	.rm-95 {
		margin: 95px;
	}

	.rm-100 {
		margin: 100px;
	}

	.rm-105 {
		margin: 105px;
	}

	.rm-110 {
		margin: 110px;
	}

	.rm-115 {
		margin: 115px;
	}

	.rm-120 {
		margin: 120px;
	}

	.rm-125 {
		margin: 125px;
	}

	.rm-130 {
		margin: 130px;
	}

	.rm-135 {
		margin: 135px;
	}

	.rm-140 {
		margin: 140px;
	}

	.rm-145 {
		margin: 145px;
	}

	.rm-150 {
		margin: 150px;
	}

	/* Margin Top */
	.rmt-0,
	.rmy-0 {
		margin-top: 0px !important;
	}

	.rmt-5,
	.rmy-5 {
		margin-top: 5px !important;
	}

	.rmt-10,
	.rmy-10 {
		margin-top: 10px;
	}

	.rmt-15,
	.rmy-15 {
		margin-top: 15px;
	}

	.rmt-20,
	.rmy-20 {
		margin-top: 20px;
	}

	.rmt-25,
	.rmy-25 {
		margin-top: 25px;
	}

	.rmt-30,
	.rmy-30 {
		margin-top: 30px;
	}

	.rmt-35,
	.rmy-35 {
		margin-top: 35px;
	}

	.rmt-40,
	.rmy-40 {
		margin-top: 40px;
	}

	.rmt-45,
	.rmy-45 {
		margin-top: 45px;
	}

	.rmt-50,
	.rmy-50 {
		margin-top: 50px;
	}

	.rmt-55,
	.rmy-55 {
		margin-top: 55px;
	}

	.rmt-60,
	.rmy-60 {
		margin-top: 60px;
	}

	.rmt-65,
	.rmy-65 {
		margin-top: 65px;
	}

	.rmt-70,
	.rmy-70 {
		margin-top: 70px;
	}

	.rmt-75,
	.rmy-75 {
		margin-top: 75px;
	}

	.rmt-80,
	.rmy-80 {
		margin-top: 80px;
	}

	.rmt-85,
	.rmy-85 {
		margin-top: 85px;
	}

	.rmt-90,
	.rmy-90 {
		margin-top: 90px;
	}

	.rmt-95,
	.rmy-95 {
		margin-top: 95px;
	}

	.rmt-100,
	.rmy-100 {
		margin-top: 100px;
	}

	.rmt-105,
	.rmy-105 {
		margin-top: 105px;
	}

	.rmt-110,
	.rmy-110 {
		margin-top: 110px;
	}

	.rmt-115,
	.rmy-115 {
		margin-top: 115px;
	}

	.rmt-120,
	.rmy-120 {
		margin-top: 120px;
	}

	.rmt-125,
	.rmy-125 {
		margin-top: 125px;
	}

	.rmt-130,
	.rmy-130 {
		margin-top: 130px;
	}

	.rmt-135,
	.rmy-135 {
		margin-top: 135px;
	}

	.rmt-140,
	.rmy-140 {
		margin-top: 140px;
	}

	.rmt-145,
	.rmy-145 {
		margin-top: 145px;
	}

	.rmt-150,
	.rmy-150 {
		margin-top: 150px;
	}

	/* Margin Bottom */
	.rmb-0,
	.rmy-0 {
		margin-bottom: 0px !important;
	}

	.rmb-5,
	.rmy-5 {
		margin-bottom: 5px !important;
	}

	.rmb-10,
	.rmy-10 {
		margin-bottom: 10px;
	}

	.rmb-15,
	.rmy-15 {
		margin-bottom: 15px;
	}

	.rmb-20,
	.rmy-20 {
		margin-bottom: 20px;
	}

	.rmb-25,
	.rmy-25 {
		margin-bottom: 25px;
	}

	.rmb-30,
	.rmy-30 {
		margin-bottom: 30px;
	}

	.rmb-35,
	.rmy-35 {
		margin-bottom: 35px;
	}

	.rmb-40,
	.rmy-40 {
		margin-bottom: 40px;
	}

	.rmb-45,
	.rmy-45 {
		margin-bottom: 45px;
	}

	.rmb-50,
	.rmy-50 {
		margin-bottom: 50px;
	}

	.rmb-55,
	.rmy-55 {
		margin-bottom: 55px;
	}

	.rmb-60,
	.rmy-60 {
		margin-bottom: 60px;
	}

	.rmb-65,
	.rmy-65 {
		margin-bottom: 65px;
	}

	.rmb-70,
	.rmy-70 {
		margin-bottom: 70px;
	}

	.rmb-75,
	.rmy-75 {
		margin-bottom: 75px;
	}

	.rmb-80,
	.rmy-80 {
		margin-bottom: 80px;
	}

	.rmb-85,
	.rmy-85 {
		margin-bottom: 85px;
	}

	.rmb-90,
	.rmy-90 {
		margin-bottom: 90px;
	}

	.rmb-95,
	.rmy-95 {
		margin-bottom: 95px;
	}

	.rmb-100,
	.rmy-100 {
		margin-bottom: 100px;
	}

	.rmb-105,
	.rmy-105 {
		margin-bottom: 105px;
	}

	.rmb-110,
	.rmy-110 {
		margin-bottom: 110px;
	}

	.rmb-115,
	.rmy-115 {
		margin-bottom: 115px;
	}

	.rmb-120,
	.rmy-120 {
		margin-bottom: 120px;
	}

	.rmb-125,
	.rmy-125 {
		margin-bottom: 125px;
	}

	.rmb-130,
	.rmy-130 {
		margin-bottom: 130px;
	}

	.rmb-135,
	.rmy-135 {
		margin-bottom: 135px;
	}

	.rmb-140,
	.rmy-140 {
		margin-bottom: 140px;
	}

	.rmb-145,
	.rmy-145 {
		margin-bottom: 145px;
	}

	.rmb-150,
	.rmy-150 {
		margin-bottom: 150px;
	}
}

/*******************************************************/
/***************** 05. Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.delay-2-0s {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.delay-0-1s {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.delay-0-2s {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.delay-0-3s {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.delay-0-4s {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.delay-0-5s {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.delay-0-6s {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.delay-0-7s {
	-webkit-animation-delay: 0.7s;
	animation-delay: 0.7s;
}

.delay-0-8s {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.delay-0-9s {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}

.delay-1-1s {
	-webkit-animation-delay: 1.1s;
	animation-delay: 1.1s;
}

.delay-1-2s {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.delay-1-3s {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}

.delay-1-4s {
	-webkit-animation-delay: 1.4s;
	animation-delay: 1.4s;
}

.delay-1-5s {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.delay-1-6s {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
}

.delay-1-7s {
	-webkit-animation-delay: 1.7s;
	animation-delay: 1.7s;
}

.delay-1-8s {
	-webkit-animation-delay: 1.8s;
	animation-delay: 1.8s;
}

.delay-1-9s {
	-webkit-animation-delay: 1.9s;
	animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
	0% {
		top: -100px;
	}

	100% {
		top: 0;
	}
}

@keyframes sticky {
	0% {
		top: -100px;
	}

	100% {
		top: 0;
	}
}

/* Hero Circle */
@-webkit-keyframes rotated_circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotated_circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Hero Circle */
@-webkit-keyframes upDownLeft {

	0%,
	100% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}

	25%,
	75% {
		-webkit-transform: translate(0px, 50px);
		transform: translate(0px, 50px);
	}

	50% {
		-webkit-transform: translate(-50px, 50px);
		transform: translate(-50px, 50px);
	}
}

@keyframes upDownLeft {

	0%,
	100% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}

	25%,
	75% {
		-webkit-transform: translate(0px, 50px);
		transform: translate(0px, 50px);
	}

	50% {
		-webkit-transform: translate(-50px, 50px);
		transform: translate(-50px, 50px);
	}
}

@-webkit-keyframes shapeAnimationOne {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(0px, 150px) rotate(90deg);
		transform: translate(0px, 150px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(150px, 0px) rotate(270deg);
		transform: translate(150px, 0px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationOne {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(0px, 150px) rotate(90deg);
		transform: translate(0px, 150px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(150px, 0px) rotate(270deg);
		transform: translate(150px, 0px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationTwo {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-150px, 0px) rotate(270deg);
		transform: translate(-150px, 0px) rotate(270deg);
	}

	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(0px, -150px) rotate(90deg);
		transform: translate(0px, -150px) rotate(90deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationTwo {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-150px, 0px) rotate(270deg);
		transform: translate(-150px, 0px) rotate(270deg);
	}

	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(0px, -150px) rotate(90deg);
		transform: translate(0px, -150px) rotate(90deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationThree {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(50px, 150px) rotate(90deg);
		transform: translate(50px, 150px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(150px, 50px) rotate(270deg);
		transform: translate(150px, 50px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationThree {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(50px, 150px) rotate(90deg);
		transform: translate(50px, 150px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(150px, 150px) rotate(180deg);
		transform: translate(150px, 150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(150px, 50px) rotate(270deg);
		transform: translate(150px, 50px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationFour {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-150px -50px) rotate(90deg);
		transform: translate(-150px -50px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(-50px, -150px) rotate(270deg);
		transform: translate(-50px, -150px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationFour {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-150px -50px) rotate(90deg);
		transform: translate(-150px -50px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(-150px, -150px) rotate(180deg);
		transform: translate(-150px, -150px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(-50px, -150px) rotate(270deg);
		transform: translate(-50px, -150px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes shapeAnimationFive {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-100px -100px) rotate(90deg);
		transform: translate(-100px -100px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(100px, 50px) rotate(180deg);
		transform: translate(100px, 50px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(-100px, 150px) rotate(270deg);
		transform: translate(-100px, 150px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@keyframes shapeAnimationFive {
	0% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}

	25% {
		-webkit-transform: translate(-100px -100px) rotate(90deg);
		transform: translate(-100px -100px) rotate(90deg);
	}

	50% {
		-webkit-transform: translate(100px, 50px) rotate(180deg);
		transform: translate(100px, 50px) rotate(180deg);
	}

	75% {
		-webkit-transform: translate(-100px, 150px) rotate(270deg);
		transform: translate(-100px, 150px) rotate(270deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px) rotate(360deg);
		transform: translate(0px, 0px) rotate(360deg);
	}
}

@-webkit-keyframes down-up-one {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(25px);
		transform: rotateX(0deg) translateY(25px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
}

@keyframes down-up-one {
	0% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}

	50% {
		-webkit-transform: rotateX(0deg) translateY(25px);
		transform: rotateX(0deg) translateY(25px);
	}

	100% {
		-webkit-transform: rotateX(0deg) translateY(0px);
		transform: rotateX(0deg) translateY(0px);
	}
}

@-webkit-keyframes down-up-two {
	0% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}

	50% {
		-webkit-transform: translate(-25px, -25px);
		transform: translate(-25px, -25px);
	}

	100% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}
}

@keyframes down-up-two {
	0% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}

	50% {
		-webkit-transform: translate(-25px, -25px);
		transform: translate(-25px, -25px);
	}

	100% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}
}

@-webkit-keyframes down-up-three {
	0% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}

	50% {
		-webkit-transform: translate(25px, -15px);
		transform: translate(25px, -15px);
	}

	100% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}
}

@keyframes down-up-three {
	0% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}

	50% {
		-webkit-transform: translate(25px, -15px);
		transform: translate(25px, -15px);
	}

	100% {
		-webkit-transform: translate(0px);
		transform: translate(0px);
	}
}

@-webkit-keyframes moveLeftRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes moveLeftRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@-webkit-keyframes zoomInOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes zoomInOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes fadeInUpRight {
	0% {
		opacity: 0;
		margin: -50px -50px 0 0;
	}

	100% {
		opacity: 1;
		margin: 0;
	}
}

@keyframes fadeInUpRight {
	0% {
		opacity: 0;
		margin: -50px -50px 0 0;
	}

	100% {
		opacity: 1;
		margin: 0;
	}
}

.fadeInUpRight {
	-webkit-animation-name: fadeInUpRight;
	animation-name: fadeInUpRight;
}

/* Preloader */
@-webkit-keyframes preloader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes preloader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/*******************************************************/
/******************* 06. Header style ******************/
/*******************************************************/
.main-header {
	position: relative;
	left: 0px;
	top: 0px;
	z-index: 999;
	width: 100%;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.main-header .header-upper {
	z-index: 5;
	width: 100%;
	position: absolute;
	padding-left: 55px;
	padding-right: 55px;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

@media only screen and (max-width: 1500px) {
	.main-header .header-upper {
		padding-left: 0;
		padding-right: 0;
	}
}

.main-header .logo-outer {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}

@media only screen and (max-width: 991px) {
	.main-header .logo-outer {
		display: none;
	}
}

.main-header .logo {
	z-index: 9;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	padding: 25px 50px;
	position: relative;
	border-radius: 12px;
	margin-top: -26px;
	margin-bottom: -26px;
	background: #00cc83;
}

@media only screen and (max-width: 1700px) and (min-width: 1500px) {
	.main-header .logo {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 1500px) {
	.main-header .logo {
		padding: 15px;
		max-width: 100px;
		margin-top: -16px;
		margin-bottom: -16px;
	}
}

.main-header .nice-select {
	height: auto;
	border: none;
	line-height: 1;
	font-size: 18px;
	padding-left: 0;
	border-radius: 0;
	font-weight: 700;
	margin-left: 75px;
	padding-right: 16px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: transparent;
}

.main-header .nice-select:after {
	right: 0;
	width: 8px;
	height: 8px;
	margin-top: -5px;
	border-color: #262d3d;
}

.main-header .nice-select .list {
	left: -18px;
	min-width: 100px;
}

.main-header.fixed-header .header-upper {
	top: 0;
	position: fixed;
	background: white;
	-webkit-animation: sticky 1s;
	animation: sticky 1s;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
	box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

.main-header.fixed-header .logo {
	margin-top: 0;
	padding-top: 15px;
	padding-bottom: 15px;
}

.main-header.fixed-header .navbar-collapse>ul>li {
	padding-top: 25px;
	padding-bottom: 25px;
}

@media only screen and (max-width: 991px) {
	.main-header.fixed-header .navbar-collapse>ul>li {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.main-header.fixed-header .mobile-logo {
	margin-top: 0;
}

/* Header Top */
.header-top {
	padding-top: 8px;
	padding-bottom: 8px;
}

.top-inner {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 336px;
	padding-right: 55px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (max-width: 1700px) and (min-width: 1500px) {
	.top-inner {
		padding-left: 286px;
	}
}

@media only screen and (max-width: 1500px) {
	.top-inner {
		padding-right: 0;
		padding-left: 176px;
	}
}

@media only screen and (max-width: 991px) {
	.top-inner {
		padding-left: 90px;
	}
}

@media only screen and (max-width: 575px) {
	.top-inner {
		padding-left: 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

@media only screen and (max-width: 575px) {
	.top-inner .top-left {
		display: none;
	}
}

.top-inner .top-left p {
	margin-bottom: 0;
	font-weight: 600;
}

.top-inner .top-left p b {
	font-weight: 700;
}

.top-inner .top-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 25px;
	border-left: 1px solid rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 1199px) {
	.top-inner .top-menu {
		display: none;
	}
}

.top-inner .top-menu li {
	margin-left: 25px;
}

.top-inner .top-menu li a:hover {
	text-decoration: underline;
}

.top-right .social-style-two a {
	margin-left: 0;
	margin-right: 25px;
}

.top-right .social-style-two a:last-child {
	margin-right: 0;
}

@media only screen and (max-width: 991px) {
	.nav-outer {
		width: 100%;
	}
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
	.main-menu {
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {
	.main-menu .collapse {
		overflow: auto;
	}
}

.main-menu .navbar-collapse {
	padding: 0px;
}

.main-menu .navbar-collapse>ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse>ul {
		display: block;
		padding: 25px 0;
		background: #ffffff;
	}

	.main-menu .navbar-collapse>ul>li:last-child {
		border-bottom: 1px solid #f3f3f3;
	}
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse {
		left: 0;
		width: 100%;
		padding: 0 15px;
		position: absolute;
	}
}

.main-menu .navbar-collapse li {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 30px 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (max-width: 1500px) {
	.main-menu .navbar-collapse li {
		padding: 25px 15px;
	}
}

@media only screen and (max-width: 1199px) {
	.main-menu .navbar-collapse li {
		padding: 25px 10px;
	}
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li {
		display: block;
		padding: 0 20px;
		border-top: 1px solid #f3f3f3;
	}
}

.main-menu .navbar-collapse li.dropdown .dropdown-btn {
	cursor: pointer;
	font-size: 13px;
	margin-left: 8px;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li.dropdown .dropdown-btn {
		position: absolute;
		right: 10px;
		top: 0;
		width: 50px;
		height: 43px;
		border-left: 1px solid #f2f2f2;
		text-align: center;
		line-height: 43px;
	}
}

.main-menu .navbar-collapse li a {
	position: relative;
	display: block;
	font-size: 18px;
	opacity: 1;
	font-weight: 700;
	color: #262d3d;
	text-transform: capitalize;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li a {
		padding: 10px 10px;
		line-height: 22px;
	}
}

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a,
.main-menu .navbar-collapse li>a:hover {
	color: #204ecf;
	text-decoration: underline;
}

.main-menu .navbar-collapse li li {
	border-top: 1px solid #f3f3f3;
}

.main-menu .navbar-collapse li li a {
	text-transform: capitalize;
}

.main-menu .navbar-collapse li .megamenu {
	position: absolute;
	left: 0px;
	top: 100%;
	width: 100%;
	z-index: 100;
	display: none;
	padding: 20px 0;
	background: #ffffff;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
		-2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
		-2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navbar-collapse li .megamenu:after {
	display: block;
	clear: both;
	content: "";
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li .megamenu {
		position: relative;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: 100%;
	}

	.main-menu .navbar-collapse li .megamenu .container {
		max-width: 100%;
	}

	.main-menu .navbar-collapse li .megamenu .row {
		margin: 0px;
	}
}

.main-menu .navbar-collapse li .megamenu ul {
	display: block;
	position: relative;
	top: 0;
	width: 100%;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.main-menu .navbar-collapse li ul {
	position: absolute;
	left: inherit;
	top: 100%;
	min-width: 250px;
	z-index: 100;
	display: none;
	background: #ffffff;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
		-2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
		-2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li ul {
		position: relative;
		display: none;
		width: 100%;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.main-menu .navbar-collapse li ul:after {
		display: block;
		clear: both;
		content: "";
	}
}

@media only screen and (max-width: 991px) and (max-width: 375px) {
	.main-menu .navbar-collapse li ul {
		min-width: auto;
	}
}

.main-menu .navbar-collapse li ul li {
	width: 100%;
	padding: 7px 20px;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li ul li {
		padding: 0 15px;
	}
}

.main-menu .navbar-collapse li ul li ul {
	left: 100%;
	top: 0%;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-collapse li ul li ul {
		left: auto;
	}
}

.main-menu .navbar-header {
	display: none;
}

@media only screen and (max-width: 991px) {
	.main-menu .navbar-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

.main-menu .navbar-header .navbar-toggle {
	float: right;
	padding: 4px 0;
	cursor: pointer;
	background: transparent;
}

.main-menu .navbar-header .navbar-toggle .icon-bar {
	background: #262d3d;
	height: 3px;
	width: 30px;
	display: block;
	margin: 7px 0;
}

.main-menu .mobile-logo {
	z-index: 5;
	padding: 10px;
	max-width: 75px;
	position: relative;
	margin-top: -15px;
	margin-bottom: -15px;
}

/* Menu Right icons */
.menu-btn-sidebar button {
	background: transparent;
}

.menu-btn-sidebar>button {
	margin-left: 25px;
}

@media only screen and (max-width: 991px) {
	.menu-btn-sidebar>button {
		display: none;
	}
}

.menu-btn-sidebar .cart {
	position: relative;
}

.menu-btn-sidebar .cart:after {
	position: absolute;
	font-weight: 700;
	font-size: 13px;
	content: "2";
	top: -10px;
	right: -15px;
	color: white;
	font-family: "Raleway", sans-serif;
	width: 19px;
	height: 19px;
	background: #00cc83;
	line-height: 19px;
	border-radius: 50%;
	text-align: center;
	line-height: 17px;
}

.menu-btn-sidebar form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: white;
	max-width: 195px;
	padding: 5px 15px;
	border-radius: 5px;
	margin-right: 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
	box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
}

@media only screen and (max-width: 1199px) {
	.menu-btn-sidebar form {
		display: none;
	}
}

.menu-btn-sidebar form input {
	border: none;
	padding: 5px 0 5px;
	background: transparent;
}

.menu-btn-sidebar form button {
	color: #204ecf;
}

/* Menu Sidebar */
.menu-sidebar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 35px;
}

@media only screen and (max-width: 991px) {
	.menu-sidebar {
		display: none;
	}
}

.menu-sidebar button {
	width: 35px;
	cursor: pointer;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: end;
	background: transparent;
}

.menu-sidebar button .icon-bar {
	height: 3px;
	width: 30px;
	margin: 3px 0;
	background: #262d3d;
}

/** hidden-sidebar * */
.hidden-bar {
	position: fixed;
	right: -350px;
	top: 0px;
	opacity: 0;
	width: 350px;
	height: 100%;
	z-index: 99999;
	overflow-y: auto;
	visibility: hidden;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	background-color: #262d3d;
}

@media only screen and (max-width: 375px) {
	.hidden-bar {
		width: 300px;
	}
}

.hidden-bar .social-style-one a {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.side-content-visible .hidden-bar {
	right: 0px;
	opacity: 1;
	visibility: visible;
}

.hidden-bar .inner-box {
	position: relative;
	padding: 100px 40px 50px;
}

@media only screen and (max-width: 375px) {
	.hidden-bar .inner-box {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.hidden-bar .inner-box .cross-icon {
	position: absolute;
	right: 30px;
	top: 30px;
	cursor: pointer;
	color: #ffffff;
	font-size: 20px;
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.hidden-bar .inner-box h4 {
	position: relative;
	color: #ffffff;
	margin-bottom: 35px;
}

/*Appointment Form */
.hidden-bar .appointment-form {
	position: relative;
}

.hidden-bar .appointment-form .form-group {
	position: relative;
	margin-bottom: 20px;
}

.hidden-bar .appointment-form input[type="text"],
.hidden-bar .appointment-form input[type="email"] {
	position: relative;
	display: block;
	width: 100%;
	line-height: 23px;
	padding: 10px 25px;
	color: #ffffff;
	font-size: 16px;
	background: none;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	border: 1px solid rgba(255, 255, 255, 0.1);
}

.hidden-bar .appointment-form textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 23px;
	padding: 10px 25px;
	color: #ffffff;
	font-size: 16px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: none;
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	resize: none;
}

.hidden-bar .appointment-form input::-webkit-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form input:-ms-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form input::-ms-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form input::placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-webkit-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form textarea:-ms-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-ms-input-placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::placeholder {
	font-size: 14px;
	color: #bdbdbd;
}

.hidden-bar .appointment-form .form-group button {
	width: 100%;
	margin-bottom: 20px;
	padding: 11px 15px 8px;
}

.form-back-drop {
	position: fixed;
	right: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: rgba(0, 0, 0, 0.7);
	visibility: hidden;
	z-index: 9990;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.side-content-visible .form-back-drop {
	opacity: 1;
	visibility: visible;
}

.main-header {
	/* Header Two */
}

.main-header.header-two .top-left b {
	color: #8b93a7;
}

.main-header.header-two .top-left i {
	color: #00cc83;
}

.main-header.header-two .top-menu a {
	color: #bbbbbd;
}

.main-header.header-two .top-menu a:hover {
	color: white;
}

.main-header.header-two .navbar-collapse li.current>a,
.main-header.header-two .navbar-collapse li.current-menu-item>a,
.main-header.header-two .navbar-collapse li>a:hover {
	color: #00cc83;
}

.main-header.header-two:not(.fixed-header) .logo-outer .nice-select:after {
	border-color: white;
}

.main-header.header-two:not(.fixed-header) .logo-outer .nice-select .current {
	color: white;
}

.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li.current>a,
.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li.current-menu-item>a,
.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li>a:hover {
	color: #00cc83;
}

.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li>a {
	color: white;
}

@media only screen and (max-width: 991px) {
	.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li>a {
		color: #415674;
	}
}

.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
	color: white;
}

@media only screen and (max-width: 991px) {
	.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
		color: #415674;
	}
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form {
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.15);
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form button,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input::-webkit-input-placeholder {
	color: white;
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form button,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input:-ms-input-placeholder {
	color: white;
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form button,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input::-ms-input-placeholder {
	color: white;
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form button,
.main-header.header-two:not(.fixed-header) .menu-btn-sidebar form input::placeholder {
	color: white;
}

.main-header.header-two:not(.fixed-header) .menu-btn-sidebar>button {
	color: white;
}

.main-header.header-two:not(.fixed-header) .icon-bar {
	background: white;
}

.main-header.header-two .bg-light-blue .top-left b {
	color: white;
}

/* Header Three */
.main-header.header-three .container {
	max-width: 1430px;
}

.main-header.header-three .top-inner {
	padding-left: 0;
	padding-right: 0;
}

.main-header.header-three .top-inner .top-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 575px) {
	.main-header.header-three .top-inner .top-left {
		display: none;
	}
}

@media only screen and (min-width: 1501px) {
	.main-header.header-three .top-inner .top-left p:not(:last-child) {
		padding-right: 25px;
		margin-right: 25px;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
}

@media only screen and (max-width: 1500px) {
	.main-header.header-three .top-inner .top-left p:last-child {
		display: none;
	}
}

.main-header.header-three .header-inner {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media only screen and (max-width: 991px) {
	.main-header.header-three .header-inner {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.main-header.header-three .logo {
	margin: 0;
	padding: 0;
	background: transparent;
}

@media only screen and (max-width: 1500px) {
	.main-header.header-three .logo {
		max-width: none;
	}
}

.main-header.header-three .main-menu .navbar-collapse>ul>li {
	position: relative;
}

.main-header.header-three .main-menu .navbar-collapse>ul>li:before {
	content: "";
	height: 3px;
	width: 0;
	left: 50%;
	top: 100%;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	background: #ffb113;
	-webkit-transform: translate(-50%, -1.5px);
	-ms-transform: translate(-50%, -1.5px);
	transform: translate(-50%, -1.5px);
}

@media only screen and (max-width: 991px) {
	.main-header.header-three .main-menu .navbar-collapse>ul>li:before {
		display: none;
	}
}

.main-header.header-three .main-menu .navbar-collapse>ul>li>a {
	color: white;
}

@media only screen and (max-width: 991px) {
	.main-header.header-three .main-menu .navbar-collapse>ul>li>a {
		color: #415674;
	}
}

.main-header.header-three .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
	color: white;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

@media only screen and (max-width: 991px) {
	.main-header.header-three .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
		color: #415674;
	}
}

.main-header.header-three .main-menu .navbar-collapse>ul>li:hover:before,
.main-header.header-three .main-menu .navbar-collapse>ul>li.current:before {
	opacity: 1;
	width: 70%;
}

.main-header.header-three .main-menu .navbar-collapse>ul>li:hover>.dropdown-btn,
.main-header.header-three .main-menu .navbar-collapse>ul>li.current>.dropdown-btn {
	color: #ffb113;
}

@media only screen and (max-width: 991px) {

	.main-header.header-three .main-menu .navbar-collapse>ul>li:hover>.dropdown-btn,
	.main-header.header-three .main-menu .navbar-collapse>ul>li.current>.dropdown-btn {
		color: #415674;
	}
}

.main-header.header-three .main-menu .navbar-collapse>ul li.current>a,
.main-header.header-three .main-menu .navbar-collapse>ul li.current-menu-item>a,
.main-header.header-three .main-menu .navbar-collapse>ul li>a:hover {
	color: #ffb113;
}

.main-header.header-three .main-menu .navbar-toggle .icon-bar {
	background: white;
}

.main-header.header-three .menu-btn-sidebar .nav-search {
	position: relative;
}

@media only screen and (max-width: 991px) {
	.main-header.header-three .menu-btn-sidebar .nav-search {
		display: none;
	}
}

.main-header.header-three .menu-btn-sidebar .nav-search>button {
	color: white;
}

.main-header.header-three .menu-btn-sidebar form {
	right: 0;
	top: 100%;
	margin-right: 0;
	min-width: 200px;
	position: absolute;
}

.main-header.header-three .menu-btn-sidebar form.hide {
	display: none;
}

.main-header.header-three .menu-btn-sidebar>button {
	color: white;
}

.main-header.header-three .menu-btn-sidebar .icon-bar {
	background: white;
}

.main-header.header-three .menu-btn-sidebar .cart:after {
	color: #262d3d;
	background: #ffb113;
}

.main-header.header-three .mobile-logo {
	padding: 0;
	margin-bottom: 0;
	max-width: 150px;
}

.main-header.header-three.fixed-header .header-upper {
	background: #262d3d;
}

.main-header.header-three.fixed-header .logo {
	margin-bottom: 0;
}

/* Header Four */
.main-header.header-four .top-inner {
	padding-left: 135px;
	padding-right: 135px;
}

@media only screen and (max-width: 1500px) {
	.main-header.header-four .top-inner {
		padding-left: 35px;
		padding-right: 35px;
	}
}

@media only screen and (max-width: 991px) {
	.main-header.header-four .top-inner {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.main-header.header-four .top-inner .top-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 575px) {
	.main-header.header-four .top-inner .top-left {
		display: none;
	}
}

@media only screen and (min-width: 1501px) {
	.main-header.header-four .top-inner .top-left p:not(:last-child) {
		padding-right: 25px;
		margin-right: 25px;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
}

@media only screen and (max-width: 1500px) {
	.main-header.header-four .top-inner .top-left p:last-child {
		display: none;
	}
}

.main-header.header-four .header-upper {
	padding-left: 135px;
	padding-right: 135px;
}

@media only screen and (max-width: 1500px) {
	.main-header.header-four .header-upper {
		padding-left: 35px;
		padding-right: 35px;
	}
}

@media only screen and (max-width: 991px) {
	.main-header.header-four .header-upper {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.main-header.header-four .logo {
	margin: 0;
	padding: 0;
	max-width: none;
	background: transparent;
}

.main-header.header-four .menu-btns .nice-select {
	margin-left: 0;
	padding-right: 0;
	margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
	.main-header.header-four .menu-btns .nice-select {
		display: none;
	}
}

.main-header.header-four .menu-btns .nice-select:after {
	display: none;
}

.main-header.header-four .menu-btns .nice-select .current {
	padding: 6px 0 6px 40px;
	/* background: url(../images/shapes/language.png) no-repeat 0 center; */
}

.main-header.header-four .menu-btns .nice-select .list {
	left: -5px;
}

.main-header.header-four .menu-btns .nice-select .list li {
	padding-left: 45px;
	/* background: url(../images/shapes/language.png) no-repeat 5px center; */
}

.main-header.header-four .mobile-logo {
	padding: 0;
	max-width: 150px;
	margin-top: 10px;
	margin-bottom: 10px;
}

/*******************************************************/
/******************* 07. Hero Section ******************/
/*******************************************************/
.hero-section {
	z-index: 1;
	overflow: hidden;
	position: relative;
}

.hero-section:before {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	z-index: -1;
	width: 330px;
	height: 330px;
	background: #f7f7f7;
	line-height: 330px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translate(-25%, 50%);
	-ms-transform: translate(-25%, 50%);
	transform: translate(-25%, 50%);
}

.hero-section:after {
	position: absolute;
	content: "";
	right: 0;
	top: 0;
	z-index: -1;
	width: 965px;
	height: 965px;
	background: #f7f7f7;
	line-height: 965px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translate(15%, -50%);
	-ms-transform: translate(15%, -50%);
	transform: translate(15%, -50%);
}

@media only screen and (max-width: 1199px) {
	.hero-section:after {
		width: 500px;
		height: 500px;
	}
}

@media only screen and (max-width: 991px) {
	.hero-section:after {
		right: 50%;
		-webkit-transform: translate(50%, -60%);
		-ms-transform: translate(50%, -60%);
		transform: translate(50%, -60%);
	}
}

@media only screen and (max-width: 575px) {
	.hero-section:after {
		width: 400px;
		height: 400px;
	}
}

@media only screen and (max-width: 480px) {
	.hero-section:after {
		width: 300px;
		height: 300px;
	}
}

.hero-content h1 {
	text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
	.hero-content h1 {
		font-size: 55px;
	}
}

@media only screen and (max-width: 767px) {
	.hero-content h1 {
		font-size: 50px;
	}
}

@media only screen and (max-width: 575px) {
	.hero-content h1 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 375px) {
	.hero-content h1 {
		font-size: 32px;
	}
}

.hero-content p {
	font-size: 18px;
}

@media only screen and (max-width: 480px) {
	.hero-content p {
		font-size: 16px;
	}
}

@media only screen and (min-width: 1501px) {
	.hero-right-images {
		margin-right: -150px;
	}
}

/* Hero Two */
.hero-section-two {
	overflow: hidden;
}

.hero-content-two {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: 1199px) {
	.hero-content-two h1 {
		font-size: 55px;
	}
}

@media only screen and (max-width: 767px) {
	.hero-content-two h1 {
		font-size: 50px;
	}
}

@media only screen and (max-width: 575px) {
	.hero-content-two h1 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 375px) {
	.hero-content-two h1 {
		font-size: 32px;
	}
}

.hero-content-two .list-style-one {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media only screen and (max-width: 375px) {
	.hero-content-two .list-style-one {
		margin-left: 30px;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: start;
	}
}

.hero-content-two .list-style-one li {
	margin-left: 20px;
	margin-right: 20px;
}

@media only screen and (max-width: 575px) {
	.hero-content-two .list-style-one li {
		font-size: 16px;
		margin-bottom: 10px;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.hero-two-btns {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.hero-two-btns .theme-btn:not(:last-child) {
	margin-right: 20px;
}

.hero-two-btns .video-play {
	left: 0;
	width: auto;
	height: auto;
	line-height: 1;
	position: relative;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
	background: transparent;
}

.hero-two-btns .video-play i {
	margin-right: 10px;
	width: 55px;
	height: 55px;
	background: white;
	line-height: 55px;
	border-radius: 50%;
	text-align: center;
	color: #204ecf;
}

.hero-two-btns .video-play span {
	font-size: 15px;
	font-weight: 800;
	text-decoration: underline;
}

.admin-speech {
	text-align: left;
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	padding: 30px 50px;
	background: #1c317c;
	border-radius: 12px;
	font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 575px) {
	.admin-speech {
		margin-left: 25px;
		margin-right: 25px;
		padding-left: 25px;
		padding-right: 25px;
	}
}

.admin-speech .image {
	position: absolute;
	left: 0;
	top: 0;
	-webkit-transform: translate(-50%, -45%);
	-ms-transform: translate(-50%, -45%);
	transform: translate(-50%, -45%);
}

.admin-speech .image img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

.admin-speech p {
	opacity: 0.8;
}

.admin-speech .name {
	opacity: 0.7;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	font-weight: 700;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.admin-speech .name:before {
	content: "";
	width: 25px;
	height: 2px;
	margin-right: 10px;
	background: #00cc83;
}

.hero-two-left,
.hero-two-right {
	z-index: -1;
	max-width: 25%;
	position: absolute;
}

.hero-two-left {
	left: 5%;
	bottom: 0;
}

.hero-two-left:before {
	content: "";
	left: -5%;
	bottom: 0;
	z-index: -2;
	width: 90%;
	height: 85%;
	position: absolute;
	background: #204ecf;
	border-radius: 200px 200px 0 0;
}

.hero-two-right {
	right: 5%;
	bottom: 100px;
}

.hero-two-right:after,
.hero-two-right:before {
	content: "";
	left: 44%;
	z-index: -2;
	position: absolute;
	border-radius: 50%;
	background: #204ecf;
}

.hero-two-right:before {
	width: 475px;
	height: 475px;
	opacity: 0.5;
	bottom: 13%;
}

.hero-two-right:after {
	width: 350px;
	height: 350px;
	bottom: 10%;
	-webkit-animation: fadeInRight 1s linear 1.5s both;
	animation: fadeInRight 1s linear 1.5s both;
}

/* Hero Three */
.hero-three-image-part {
	z-index: 1;
	max-width: 630px;
	text-align: center;
	position: relative;
	margin-left: -85px;
	padding-left: 50px;
	padding-right: 50px;
}

@media only screen and (max-width: 1500px) {
	.hero-three-image-part {
		margin-left: 0;
	}
}

.hero-three-image-part .hero-chart {
	top: 0;
	right: 10%;
	padding: 25px;
	max-width: 190px;
	background: white;
	position: absolute;
	text-align: center;
	border-radius: 20px;
	-webkit-box-shadow: 10px 0 60px rgba(75, 131, 252, 0.15);
	box-shadow: 10px 0 60px rgba(75, 131, 252, 0.15);
}

@media only screen and (max-width: 575px) {
	.hero-three-image-part .hero-chart {
		display: none;
	}
}

.hero-three-image-part .hero-chart h5 {
	margin: 15px 0 0;
	line-height: 25px;
}

.hero-three-image-part .hero-circle {
	left: 0;
	bottom: 10%;
	z-index: -1;
	position: absolute;
	-webkit-animation: 35s rotated_circle infinite;
	animation: 35s rotated_circle infinite;
}

.hero-three-image-part .about-image-over-item:first-child {
	top: 25%;
	left: -25%;
}

@media only screen and (max-width: 1500px) {
	.hero-three-image-part .about-image-over-item:first-child {
		left: 0;
		top: 50%;
	}
}

.hero-three-image-part .about-image-over-item:last-child {
	right: 0;
	bottom: 20%;
}

@media only screen and (max-width: 575px) {
	.hero-three-image-part .about-image-over-item:last-child {
		bottom: 0;
	}
}

.hero-three-image-part:after {
	content: "";
	width: 590px;
	height: 590px;
	opacity: 0.1;
	left: 50%;
	top: 50%;
	z-index: -1;
	position: absolute;
	border-radius: 50%;
	-webkit-transform: translate(-60%, -52%);
	-ms-transform: translate(-60%, -52%);
	transform: translate(-60%, -52%);
	background: -webkit-gradient(linear,
			left top,
			left bottom,
			from(#0f256e),
			to(white));
	background: -webkit-linear-gradient(#0f256e 0%, white 100%);
	background: -o-linear-gradient(#0f256e 0%, white 100%);
	background: linear-gradient(#0f256e 0%, white 100%);
}

@media only screen and (max-width: 991px) {
	.hero-three-image-part:after {
		width: 400px;
		height: 400px;
	}
}

@media only screen and (max-width: 480px) {
	.hero-three-image-part:after {
		width: 200px;
		height: 200px;
	}
}

.hero-content-three .hero-sub-title {
	font-size: 20px;
	font-weight: 700;
	display: inline-block;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
}

.hero-content-three .hero-sub-title span {
	font-size: 30px;
	color: #204ecf;
}

.hero-content-three .hero-sub-title:before {
	content: "\f00c";
	font-weight: 600;
	margin-right: 15px;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

.hero-content-three h1 {
	font-size: 58px;
}

@media only screen and (max-width: 1199px) {
	.hero-content-three h1 {
		font-size: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.hero-content-three h1 {
		font-size: 45px;
	}
}

@media only screen and (max-width: 575px) {
	.hero-content-three h1 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 375px) {
	.hero-content-three h1 {
		font-size: 29px;
	}
}

.hero-content-three h1 span {
	padding-right: 15px;
	margin-right: -15px;
	padding-bottom: 6px;
	margin-bottom: -6px;
	display: inline-block;
	/* background: url(../images/shapes/title-bg.png) no-repeat right bottom; */
}

.hero-content-three .list-style-one {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.hero-content-three .list-style-one li:not(:last-child) {
	margin-right: 35px;
}

.hero-content-three .list-style-one li:before {
	background: #204ecf;
}

.hero-content-three .hero-btns .theme-btn:first-child {
	margin-right: 5px;
}

.hero-section-three .hero-rectangle {
	position: absolute;
	z-index: -2;
	bottom: 10%;
	right: 8%;
	-webkit-animation: 20s moveLeftRight infinite;
	animation: 20s moveLeftRight infinite;
}

.hero-section-three .hero-circles-one,
.hero-section-three .hero-circles-two,
.hero-section-three .hero-circles-two:after,
.hero-section-three .hero-circles-one:after,
.hero-section-three .hero-circles-one:before {
	z-index: -1;
	content: "";
	position: absolute;
	border-radius: 50%;
	background: rgba(30, 40, 69, 0.03);
}

.hero-section-three .hero-circles-one {
	width: 280px;
	height: 280px;
	top: 0;
	right: 0;
	-webkit-transform: translate(35%, -20px);
	-ms-transform: translate(35%, -20px);
	transform: translate(35%, -20px);
}

@media only screen and (max-width: 991px) {
	.hero-section-three .hero-circles-one {
		width: 150px;
		height: 150px;
	}
}

.hero-section-three .hero-circles-two {
	width: 780px;
	height: 780px;
	top: 0;
	right: 0;
	-webkit-transform: translate(45%, -35%);
	-ms-transform: translate(45%, -35%);
	transform: translate(45%, -35%);
}

@media only screen and (max-width: 991px) {
	.hero-section-three .hero-circles-two {
		width: 450px;
		height: 450px;
	}
}

.hero-section-three .hero-circles-two:after,
.hero-section-three .hero-circles-one:after,
.hero-section-three .hero-circles-one:before {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.hero-section-three .hero-circles-one:before {
	width: calc(100% + 150px);
	height: calc(100% + 150px);
}

@media only screen and (max-width: 991px) {
	.hero-section-three .hero-circles-one:before {
		width: calc(100% + 100px);
		height: calc(100% + 100px);
	}
}

.hero-section-three .hero-circles-one:after {
	width: calc(100% + 330px);
	height: calc(100% + 330px);
}

@media only screen and (max-width: 991px) {
	.hero-section-three .hero-circles-one:after {
		width: calc(100% + 200px);
		height: calc(100% + 200px);
	}
}

.hero-section-three .hero-circles-two:after {
	width: calc(100% + 200px);
	height: calc(100% + 200px);
}

@media only screen and (max-width: 991px) {
	.hero-section-three .hero-circles-two:after {
		width: calc(100% + 100px);
		height: calc(100% + 100px);
	}
}

/*******************************************************/
/******************* 10. Page Banner *******************/
/*******************************************************/
.page-banner-area {
	background-size: cover;
	background-position: center;
	z-index: 1;
	position: relative;
	padding-top: 215px;
	padding-bottom: 125px;
}

.page-banner-area::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.85;
	background-color: #081a55;
}

@media only screen and (max-width: 1500px) {
	.page-banner-area {
		padding-top: 205px;
	}
}

@media only screen and (max-width: 991px) {
	.page-banner-area {
		padding-top: 138px;
		padding-bottom: 75px;
	}
}

.page-banner-area:before {
	mix-blend-mode: multiply;
}

.page-banner-area .circle-one,
.page-banner-area .circle-two {
	top: 45%;
}

.page-title {
	font-size: 60px;
	text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
	.page-title {
		font-size: 50px;
	}
}

@media only screen and (max-width: 575px) {
	.page-title {
		font-size: 45px;
	}
}

@media only screen and (max-width: 480px) {
	.page-title {
		font-size: 40px;
	}
}

@media only screen and (max-width: 375px) {
	.page-title {
		font-size: 35px;
	}
}

.breadcrumb {
	padding: 0;
	font-size: 20px;
	background: transparent;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-transform: capitalize;
}

@media only screen and (max-width: 375px) {
	.breadcrumb {
		font-size: 16px;
	}
}

.breadcrumb-item.active {
	color: #00cc83;
	text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item::before {
	content: "\f101";
	font-weight: 600;
	color: white;
	float: left;
	font-size: 16px;
	margin: 5px 10px 0;
	font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 375px) {
	.breadcrumb-item+.breadcrumb-item::before {
		margin-top: 0;
	}
}

/*******************************************************/
/**************** 09. Features Section *****************/
/*******************************************************/
.feature-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 355px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
	.feature-item {
		display: block;
	}
}

.feature-item .image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 100px;
	height: 100px;
	margin-right: 30px;
	text-align: center;
	border-radius: 24px;
	background: #213780;
	line-height: 100px;
}

@media only screen and (max-width: 480px) {
	.feature-item .image {
		margin-bottom: 15px;
	}
}

.feature-item .icon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-right: 25px;
	color: #204ecf;
	width: 60px;
	height: 60px;
	background: #f7f7f7;
	line-height: 60px;
	border-radius: 50%;
	text-align: center;
}

@media only screen and (max-width: 480px) {
	.feature-item .icon {
		margin-bottom: 15px;
	}
}

.feature-item p {
	margin-bottom: 0;
}

.text-white .feature-item p {
	opacity: 0.6;
}

/* Feature Two */
.feature-two-item {
	z-index: 1;
	padding-top: 45px;
	position: relative;
	padding-left: 70px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 375px) {
	.feature-two-item {
		padding-left: 25px;
	}
}

.feature-two-item .number {
	position: absolute;
	font-size: 250px;
	font-weight: 700;
	left: 0;
	top: -30px;
	z-index: -1;
	opacity: 0.08;
	line-height: 1;
	color: transparent;
	-webkit-text-stroke: 1px #00cc83;
}

@media only screen and (max-width: 375px) {
	.feature-two-item .number {
		font-size: 150px;
	}
}

.feature-two-item .icon {
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
}

.feature-two-item .details-btn {
	line-height: 1;
	font-size: 22px;
	margin-left: 3px;
}

/* Feature Three */
.feature-three-item {
	padding: 30px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 30px;
	border: 1px solid #e9eaeb;
}

.feature-three-item:hover {
	border-color: white;
	-webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
	box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
}

.feature-three-item .icon {
	height: 50px;
	margin-bottom: 30px;
	color: #00cc83;
}

.feature-three-item .icon i {
	line-height: 1;
	font-size: 50px;
}

.feature-three-item h4 {
	margin-bottom: 15px;
}

.feature-three-item .details-btn {
	line-height: 1;
	font-size: 22px;
}

.why-learn-feature .feature-three-item {
	padding: 0;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.why-learn-feature .feature-three-item .icon {
	margin-bottom: 22px;
}

/* Feature Four */
.freature-section-four:after,
.freature-section-four:before {
	content: "";
	width: 320px;
	height: 320px;
	z-index: -1;
	border-radius: 50%;
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.07);
}

.freature-section-four:before {
	left: 5%;
	bottom: 0;
}

.freature-section-four:after {
	top: 35%;
	right: 5%;
}

.feature-four-item {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;
}

.feature-four-item img {
	width: 100%;
}

.feature-four-item .content {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 25px 35px;
	position: absolute;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background: -webkit-gradient(linear,
			left top,
			right top,
			from(rgba(0, 0, 0, 0.90196)),
			to(rgba(0, 0, 0, 0.2)));
	background: -webkit-linear-gradient(left,
			rgba(0, 0, 0, 0.90196) 0%,
			rgba(0, 0, 0, 0.2) 100%);
	background: -o-linear-gradient(left,
			rgba(0, 0, 0, 0.90196) 0%,
			rgba(0, 0, 0, 0.2) 100%);
	background: linear-gradient(90deg,
			rgba(0, 0, 0, 0.90196) 0%,
			rgba(0, 0, 0, 0.2) 100%);
}

.feature-four-item .content .number {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	font-weight: 700;
	margin-bottom: auto;
	color: #262d3d;
	width: 40px;
	height: 40px;
	background: #ffb113;
	line-height: 40px;
	border-radius: 50%;
	text-align: center;
}

.feature-four-item .content h5 {
	color: white;
	margin-bottom: 2px;
}

.feature-four-item .content .tour-type {
	color: white;
	opacity: 0.6;
}

.feature-four-item:hover {
	border-radius: 7px;
	-webkit-box-shadow: 0 25px 60px rgba(76, 76, 76, 0.25);
	box-shadow: 0 25px 60px rgba(76, 76, 76, 0.25);
}

.feature-four-item:hover .number {
	color: white;
	background: #00cc83;
}

/* Feature Five */
.feature-five-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 375px) {
	.feature-five-item {
		display: block;
	}
}

.feature-five-item .icon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 50px;
	margin: 5px 20px 15px 0;
}

.feature-five-item h4 {
	font-size: 24px;
	margin-bottom: 15px;
}

.feature-five-item p {
	margin-bottom: 0;
}

.feature-five-item:not(:last-child) {
	margin-bottom: 30px;
}

/* Feature Six */
.feature-six-item {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: white;
	margin-bottom: 30px;
	border: 1px solid #e9eaeb;
}

.feature-six-item .content {
	padding: 40px 30px 15px;
}

.feature-six-item .content .icon {
	height: 50px;
	margin-bottom: 20px;
}

.feature-six-item .read-more {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 13px 30px;
	text-decoration: none;
	border-top: 1px solid #e9eaeb;
}

.feature-six-item:hover {
	border-color: white;
	-webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
	box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
}

.feature-six-item:hover .read-more {
	color: white;
	background: #00cc83;
	border-color: #00cc83;
}

/*******************************************************/
/****************** 10. About Section ******************/
/*******************************************************/
.about-man {
	z-index: 1;
	text-align: center;
	position: relative;
}

.about-man:before {
	content: "";
	left: 50%;
	top: 0;
	z-index: -1;
	position: absolute;
	width: 540px;
	height: 540px;
	background: #f7f7f7;
	line-height: 540px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translate(-55%, -10%);
	-ms-transform: translate(-55%, -10%);
	transform: translate(-55%, -10%);
}

@media only screen and (min-width: 1501px) {
	.about-content {
		margin-left: 60px;
	}
}

.about-btns .theme-btn {
	margin-right: 30px;
}

/* About Two */
.about-two-left {
	max-width: 500px;
	position: relative;
}

@media only screen and (max-width: 991px) {
	.about-two-left {
		max-width: none;
	}
}

.about-two-images img {
	width: 70%;
	border-radius: 10px;
}

.about-two-images img:first-child {
	margin-left: 30%;
}

.about-two-images img:last-child {
	margin-top: -10%;
	margin-right: 30%;
}

.about-content-two {
	max-width: 490px;
}

@media only screen and (min-width: 1501px) {
	.about-content-two {
		margin-left: auto;
	}
}

@media only screen and (max-width: 991px) {
	.about-content-two {
		max-width: none;
	}
}

.about-image-over-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px 35px;
	min-height: 70px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: white;
	position: absolute;
	border-radius: 35px;
	-webkit-box-shadow: 10px 0 60px rgba(75, 131, 252, 0.15);
	box-shadow: 10px 0 60px rgba(75, 131, 252, 0.15);
}

@media only screen and (max-width: 480px) {
	.about-image-over-item {
		min-height: 50px;
		padding: 5px 20px;
	}
}

.about-image-over-item:first-child {
	top: 15%;
	left: -15%;
}

@media only screen and (max-width: 1500px) {
	.about-image-over-item:first-child {
		left: 0;
	}
}

.about-image-over-item:last-child {
	bottom: 15%;
	right: -15%;
}

@media only screen and (max-width: 1500px) {
	.about-image-over-item:last-child {
		right: 0;
	}
}

.about-image-over-item img {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-right: 15px;
}

@media only screen and (max-width: 480px) {
	.about-image-over-item img {
		width: 25px;
	}
}

.about-image-over-item h5 {
	margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
	.about-image-over-item h5 {
		font-size: 16px;
	}
}

/* About Three */
.about-three-image {
	padding-right: 50px;
	margin-right: -75px;
}

@media only screen and (max-width: 1199px) {
	.about-three-image {
		margin-right: 0;
	}
}

.about-three-image-content {
	padding: 40px 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: -85px -50px 0 85px;
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
	.about-three-image-content {
		margin-left: 10px;
	}
}

@media only screen and (max-width: 767px) {
	.about-three-image-content {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 575px) {
	.about-three-image-content {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 480px) {
	.about-three-image-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.about-three-image-content h4 {
	font-size: 24px;
	margin-bottom: 0;
	font-weight: 600;
}

@media only screen and (max-width: 575px) {
	.about-three-image-content h4 {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 375px) {
	.about-three-image-content h4 {
		font-size: 20px;
	}
}

.about-content-three {
	max-width: 500px;
	margin-left: auto;
}

@media only screen and (max-width: 991px) {
	.about-content-three {
		margin-left: 0;
	}
}

/* About Four */
.about-four-content .list-style-four li:before {
	color: #00cc83;
}

.about-four-right-part {
	position: relative;
}

.about-four-right-part .image-one,
.about-four-right-part .image-two {
	width: 65%;
}

.about-four-right-part .image-one {
	display: block;
	margin-left: auto;
	margin-bottom: -25%;
}

@media only screen and (max-width: 375px) {
	.about-four-right-part .image-one {
		margin-bottom: -15%;
	}
}

.about-four-right-part .saticfiction {
	top: 10%;
	left: 10%;
	max-width: 205px;
	text-align: center;
	position: absolute;
	padding: 35px 30px 15px;
}

@media only screen and (max-width: 480px) {
	.about-four-right-part .saticfiction {
		top: 0;
		left: 0;
		max-width: 150px;
		padding: 25px 15px 5px;
	}

	.about-four-right-part .saticfiction h4 {
		font-size: 16px;
	}
}

.about-four-right-part .saticfiction .counter-number {
	margin-bottom: 10px;
	display: inline-block;
}

@media only screen and (max-width: 575px) {
	.about-four-right-part .saticfiction .counter-number {
		margin-left: 0;
	}
}

.about-four-right-part .experience {
	right: 5%;
	bottom: 4%;
	max-width: 155px;
	text-align: center;
	position: absolute;
	padding: 25px 25px 10px;
}

@media only screen and (max-width: 480px) {
	.about-four-right-part .experience {
		right: 0;
		bottom: 0;
		max-width: 125px;
		padding: 15px 15px 1px;
	}

	.about-four-right-part .experience h5 {
		font-size: 16px;
	}
}

.about-four-right-part .experience i {
	font-size: 50px;
	margin-bottom: 15px;
	display: inline-block;
}

/* About Page */
.about-page-middle {
	position: relative;
	max-width: 500px;
}

.about-page-middle img {
	width: 100%;
}

.about-page-middle .circle-content {
	width: 220px;
	height: 220px;
	left: 50%;
	top: 50%;
	color: white;
	padding: 20px;
	line-height: 1;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	position: absolute;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-family: "Raleway", sans-serif;
	background: #204ecf;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.about-page-middle .circle-content b {
	font-size: 60px;
	margin-bottom: 15px;
}

.about-page-middle .circle-content span {
	line-height: 1.5;
	margin-bottom: 10px;
}

@media only screen and (max-width: 480px) {
	.about-page-middle .circle-content {
		width: 150px;
		height: 150px;
		font-size: 16px;
	}

	.about-page-middle .circle-content b {
		font-size: 40px;
		margin-bottom: 8px;
	}
}

/* Rules And Condition */
.rules-condition-content .section-title {
	margin-right: -30px;
}

@media only screen and (max-width: 991px) {
	.rules-condition-content .section-title {
		margin-right: 0;
	}
}

.rules-condition-image-part {
	position: relative;
}

.rules-condition-image-part img {
	width: 100%;
}

.rules-condition-image-part .logo {
	position: absolute;
	left: 58.333333%;
	top: 50%;
	padding: 35px;
	width: 150px;
	height: 150px;
	background: #00cc83;
	line-height: 150px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	line-height: 1;
}

@media only screen and (max-width: 375px) {
	.rules-condition-image-part .logo {
		width: 100px;
		height: 100px;
		padding: 25px;
	}
}

/*******************************************************/
/****************** 11. Course Section *****************/
/*******************************************************/
.coach-filter {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -10px;
	margin-right: -10px;
}

.coach-filter li {
	margin: 5px;
	cursor: pointer;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 5px;
	padding: 4px 20px 2px;
	background: rgba(32, 78, 207, 0.1);
}

@media only screen and (max-width: 767px) {
	.coach-filter li {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.coach-filter li:hover,
.coach-filter li.current {
	color: white;
	background: #00cc83;
}

.coach-item {
	margin-bottom: 30px;
}

.coach-image {
	position: relative;
}

.coach-image img {
	width: 100%;
}

.coach-image .category {
	top: 20px;
	left: 20px;
	color: white;
	font-size: 15px;
	font-weight: 700;
	position: absolute;
	border-radius: 5px;
	padding: 3px 15px 1px;
	text-transform: uppercase;
	background: #204ecf;
}

.coach-content {
	background: white;
	position: relative;
	border-radius: 5px;
	padding: 25px 30px;
	margin: -50px 30px 0;
	-webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
	box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
}

@media only screen and (max-width: 480px) {
	.coach-content {
		margin-left: 15px;
		margin-right: 15px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media only screen and (max-width: 375px) {
	.coach-content h4 {
		font-size: 18px;
	}
}

.coach-content .label {
	font-weight: 600;
	margin-bottom: 5px;
	display: inline-block;
}

.coach-content .ratting-price {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 15px;
	padding-bottom: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #e9eaeb;
}

.coach-content .ratting {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-right: 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.coach-content .ratting i {
	color: #ffa200;
	font-size: 14px;
	margin-right: 4px;
}

@media only screen and (max-width: 375px) {
	.coach-content .ratting i {
		font-size: 12px;
	}
}

.coach-content .ratting span {
	font-size: 20px;
	font-weight: 500;
}

@media only screen and (max-width: 375px) {
	.coach-content .ratting span {
		font-size: 18px;
	}
}

.coach-content .price {
	font-size: 20px;
	font-weight: 800;
	color: #00cc83;
}

@media only screen and (max-width: 375px) {
	.coach-content .price {
		font-size: 16px;
	}
}

.coach-content .price:before {
	content: "$";
}

.coach-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.coach-footer li i {
	margin-right: 5px;
}

.coach-footer li:not(:last-child) {
	margin-right: 15px;
}

/* Coach Style Two */
.coach-slider {
	margin-left: -15px;
	margin-right: -15px;
}

.coach-slider .slick-dots {
	margin-top: 10px;
}

.coach-item.style-two {
	margin-left: 15px;
	margin-right: 15px;
}

.coach-item.style-two .coach-image img {
	border-radius: 8px;
}

.coach-item.style-two .coach-content {
	margin: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 30px 10px 0;
}

.coach-item.style-two .coach-content .category {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 5px;
	display: inline-block;
	color: #204ecf;
}

.coach-item.style-two .ratting-price {
	margin-top: 8px;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.coach-item.style-two .ratting-price .price {
	top: 0;
	right: 30px;
	color: white;
	font-size: 32px;
	font-weight: 800;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 75px;
	height: 75px;
	background: #00cc83;
	line-height: 75px;
	border-radius: 50%;
	text-align: center;
}

.coach-item.style-two .ratting-price .price:before {
	font-size: 15px;
}

.coach-item.style-two .coach-footer {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.coach-item.style-two.coach-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin: 0 0 40px;
	position: relative;
	padding: 33px 65px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #e9eaeb;
}

.coach-item.style-two.coach-list:hover {
	border-color: white;
	-webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
	box-shadow: 10px 0 60px rgba(76, 76, 76, 0.1);
}

@media only screen and (max-width: 767px) {
	.coach-item.style-two.coach-list {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 575px) {
	.coach-item.style-two.coach-list {
		display: block;
	}
}

.coach-item.style-two.coach-list .coach-image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 33%;
	margin-left: 30px;
	margin-right: 30px;
}

@media only screen and (max-width: 767px) {
	.coach-item.style-two.coach-list .coach-image {
		margin-left: 0;
	}
}

@media only screen and (max-width: 575px) {
	.coach-item.style-two.coach-list .coach-image {
		width: 100%;
		max-width: 200px;
		margin-bottom: 15px;
	}
}

.coach-item.style-two.coach-list .coach-image img {
	border-radius: 50%;
}

.coach-item.style-two.coach-list .coach-content {
	padding: 0;
	position: static;
}

.coach-item.style-two.coach-list .price {
	top: 90px;
	left: 65px;
	right: auto;
}

@media only screen and (max-width: 767px) {
	.coach-item.style-two.coach-list .price {
		left: 25px;
	}
}

/* Coach Timeline */
.coach-item.coach-timeline {
	margin-bottom: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 767px) {
	.coach-item.coach-timeline {
		display: block;
	}
}

.coach-item.coach-timeline .coach-image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 50%;
}

@media only screen and (max-width: 767px) {
	.coach-item.coach-timeline .coach-image {
		width: 100%;
	}
}

.coach-item.coach-timeline .coach-image img {
	height: 100%;
}

.coach-item.coach-timeline .coach-content {
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 45px;
	padding-right: 45px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 30px;
}

@media only screen and (max-width: 375px) {
	.coach-item.coach-timeline .coach-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 480px) {

	.course-list-area,
	.course-right-area {
		padding-top: 85px;
	}
}

/* Course Details */
.course-details-content h3,
.course-details-content p {
	margin-bottom: 15px;
}

.course-details-content h2 {
	font-size: 42px;
	margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
	.course-details-content h2 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 575px) {
	.course-details-content h2 {
		font-size: 30px;
	}
}

@media only screen and (max-width: 480px) {
	.course-details-content h2 {
		font-size: 25px;
	}
}

.course-details-content h3 {
	font-size: 27px;
}

@media only screen and (max-width: 480px) {
	.course-details-content h3 {
		font-size: 22px;
	}
}

.course-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: end;
	margin-bottom: 20px;
}

.course-header .category {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 5px;
	margin-right: 15px;
	color: #007dc6;
}

.course-header img {
	margin-right: 10px;
	margin-bottom: 5px;
}

.course-header .off {
	color: white;
	line-height: 1;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 5px;
	margin-right: 30px;
	border-radius: 13px;
	padding: 8px 13px 5px;
	text-transform: uppercase;
	background: #007dc6;
}

.course-header .ratting {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 5px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.course-header .ratting i {
	color: #ffa200;
	font-size: 14px;
	margin-right: 5px;
}

.course-header .ratting span {
	font-size: 20px;
}

.author-date-enroll {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
}

.author-date-enroll li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.author-date-enroll li:not(:last-child) {
	margin-right: 35px;
	position: relative;
	padding-right: 35px;
}

.author-date-enroll li:not(:last-child):after {
	content: "";
	right: 0;
	top: 50%;
	width: 2px;
	height: 20px;
	position: absolute;
	background: #e9eaeb;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
	.author-date-enroll li:not(:last-child) {
		padding-right: 0;
		margin-right: 15px;
	}

	.author-date-enroll li:not(:last-child):after {
		display: none;
	}
}

.author-date-enroll li img {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-right: 20px;
}

.author-date-enroll li h6 {
	font-size: 18px;
	margin-bottom: 0;
}

.author-date-enroll li i {
	margin-right: 10px;
	color: #204ecf;
}

.course-video-list li {
	font-weight: 600;
	padding: 15px 40px;
}

.course-video-list li:not(:last-child) {
	border-bottom: 1px solid #e9eaeb;
}

@media only screen and (max-width: 480px) {
	.course-video-list li {
		font-size: 14px;
		line-height: 1.2;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.course-video-list li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: start;
}

.course-video-list li a .title {
	margin-right: auto;
}

.course-video-list li a i:not(:last-child) {
	margin-left: 10px;
	margin-right: 10px;
	color: #204ecf;
}

.course-video-list li a i:last-child {
	color: #9a9a9a;
	font-size: 14px;
	margin-left: 10px;
}

.instructor-image,
.student-average-feedback {
	max-width: 270px;
}

@media only screen and (max-width: 575px) {

	.instructor-image,
	.student-average-feedback {
		margin-bottom: 20px;
	}
}

.instructor-details h4 {
	margin-bottom: 2px;
}

.instructor-details .designations {
	font-weight: 700;
	color: #204ecf;
}

.instructor-details .ratting i {
	color: #ffa200;
	font-size: 14px;
	margin-right: 2px;
}

.instructor-details .ratting span {
	font-size: 20px;
}

.instructor-details .social-style-two a:first-child {
	margin-left: 0;
}

.student-average-feedback {
	padding: 50px;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media only screen and (max-width: 575px) {
	.student-average-feedback {
		height: auto;
	}
}

.student-average-feedback b {
	display: block;
	line-height: 1;
	font-size: 65px;
	font-weight: 700;
	margin-bottom: 20px;
	font-family: "Raleway", sans-serif;
}

.student-average-feedback .ratting i {
	margin: 1px;
	font-size: 14px;
}

.student-average-feedback h6 {
	opacity: 0.6;
	font-size: 18px;
}

.student-feedback-author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.student-feedback-author img {
	width: 65px;
	height: 65px;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-right: 20px;
}

.student-feedback-author h4 {
	margin-bottom: 0;
}

.student-feedback-author .designations {
	font-weight: 700;
	color: #204ecf;
}

.ratting-total {
	margin-bottom: -10px;
}

.ratting-total-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.ratting-total-item .ratting {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 120px;
	margin-top: 8px;
	margin-right: 20px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.ratting-total-item .ratting i {
	color: #ffa200;
	font-size: 13px;
	margin-right: 5px;
}

.ratting-total-item .ratting span {
	margin-top: 3px;
	font-size: 20px;
}

.ratting-total-item .ratting-bar {
	width: 100%;
	height: 3px;
	margin-right: 20px;
	background: rgba(32, 78, 207, 0.1);
}

.ratting-total-item .ratting-bar span {
	height: 100%;
	display: block;
	background: #204ecf;
}

.ratting-total-item .ratting-number {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 80px;
	font-size: 16px;
	font-weight: 700;

}

/* Course Home Two */
.coach-section-two:after,
.coach-section-two:before,
.coach-section-two .for-circle:after,
.coach-section-two .for-circle:before {
	content: "";
	width: 320px;
	height: 320px;
	z-index: -1;
	border-radius: 50%;
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {

	.coach-section-two:after,
	.coach-section-two:before,
	.coach-section-two .for-circle:after,
	.coach-section-two .for-circle:before {
		width: 150px;
		height: 150px;
	}
}

.coach-section-two:before {
	left: 3%;
	bottom: 18%;
}

.coach-section-two:after {
	left: 6%;
	bottom: 7%;
}

.coach-section-two .for-circle:before {
	top: 10%;
	right: 8%;
}

.coach-section-two .for-circle:after {
	top: 20%;
	right: 3%;
}

.coach-item-two {
	margin-bottom: 30px;
	margin-left: 15px;
	margin-right: 15px;
}

.coach-item-two .image img {
	width: 100%;
	border-radius: 7px 7px 0 0;
}

.coach-item-two .content {
	position: relative;
	padding: 30px 40px;
	background: white;
	border-radius: 0 0 7px 7px;
}

@media only screen and (max-width: 375px) {
	.coach-item-two .content {
		padding-left: 20px;
		padding-right: 20px;
	}

	.coach-item-two .content h4 {
		font-size: 20px;
	}
}

.coach-item-two .content .ratting-star {
	top: -30px;
	right: 30px;
	color: white;
	position: absolute;
	width: 60px;
	height: 60px;
	background: #204ecf;
	line-height: 60px;
	border-radius: 50%;
	text-align: center;
}

.coach-item-two .content .category {
	font-weight: 700;
	padding: 1px 15px;
	margin-bottom: 18px;
	color: #262d3d;
	display: inline-block;
	background: #ffb113;
	border-radius: 0 15px 15px 0;
}

.coach-item-two .content .coach-footer {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-top: 8px;
}

.coach-item-two .content .coach-footer li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.coach-item-two .content .coach-footer li img {
	max-width: 35px;
	margin-right: 10px;
	border-radius: 50%;
}

@media only screen and (max-width: 375px) {
	.coach-item-two .content .coach-footer li img {
		max-width: 25px;
	}
}

.coach-item-two .content .coach-footer li i {
	color: #204ecf;
}

/* Course Three */
.coach-section-three .slick-dots {
	margin-top: 30px;
}

.coach-item-three {
	margin: 0 15px 30px;
}

.coach-item-three .image {
	position: relative;
}

.coach-item-three .image img {
	width: 100%;
}

.coach-item-three .image .price {
	color: white;
	right: 30px;
	bottom: -20px;
	line-height: 1;
	font-size: 24px;
	font-weight: 800;
	padding-top: 5px;
	width: 91px;
	height: 62px;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	/* background: url(../images/coachs/course-price-bg.png) 100% 100%; */
}

.coach-item-three .image .price:before {
	content: "$";
}

.coach-item-three .content {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: white;
	padding: 25px 40px 30px;
}

@media only screen and (max-width: 375px) {
	.coach-item-three .content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.coach-item-three .content .coach-footer {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: start;
}

.coach-item-three .content .coach-footer li:not(:last-child) {
	margin-right: 25px;
}

.coach-item-three .content h4 {
	margin: 12px 0 25px;
}

@media only screen and (max-width: 375px) {
	.coach-item-three .content h4 {
		font-size: 18px;
	}
}

.coach-item-three .content h4 a:hover {
	color: #204ecf;
	text-decoration: underline;
}

.coach-item-three .content .author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 25px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid #e9eaeb;
}

.coach-item-three .content .author img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	margin-right: 20px;
}

.coach-item-three .content .author h6 {
	margin: 0;
	font-size: 18px;
}

.coach-item-three:hover .content {
	-webkit-box-shadow: 10px 0 60px rgba(0, 204, 131, 0.1);
	box-shadow: 10px 0 60px rgba(0, 204, 131, 0.1);
}

/*******************************************************/
/**************** 12. Work Step Section ****************/
/*******************************************************/
.work-step-wrap {
	margin-left: -30px;
	margin-right: -30px;
}

.work-step-wrap .work-step-item {
	margin: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 480px) {
	.work-step-wrap .work-step-item {
		display: block;
	}
}

.work-step-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: white;
	border-radius: 5px;
	padding: 35px 35px 15px;
	-webkit-box-shadow: 10px 0 33px rgba(30, 40, 69, 0.07);
	box-shadow: 10px 0 33px rgba(30, 40, 69, 0.07);
}

@media only screen and (max-width: 480px) {
	.work-step-item {
		display: block;
	}
}

@media only screen and (max-width: 375px) {
	.work-step-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.work-step-item .number {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: white;
	font-size: 24px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	font-weight: 600;
	margin-right: 30px;
	margin-bottom: 15px;
	display: inline-block;
	font-family: "Raleway", sans-serif;
	border: 1px solid transparent;
	width: 50px;
	height: 50px;
	background: #204ecf;
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
}

.work-step-item h4,
.work-step-item p {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.work-step-item:hover {
	background: #204ecf;
}

.work-step-item:hover .number {
	border-color: white;
}

.work-step-item:hover h4,
.work-step-item:hover p {
	color: white;
}

.slider-arrow-btns button {
	width: 45px;
	height: 45px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	line-height: 45px;
	border-radius: 5px;
	color: #00cc83;
	background: transparent;
	border: 1px solid #e9eaeb;
}

.slider-arrow-btns button:hover,
.slider-arrow-btns button:focus {
	color: white;
	background: #00cc83;
	border-color: #00cc83;
}

.slider-arrow-btns button:not(:last-child) {
	margin-right: 5px;
}

.bebefit-work-steps .work-step-item {
	padding-top: 25px;
	padding-bottom: 8px;
}

.bebefit-work-steps .work-step-item:not(:last-child) {
	margin-bottom: 30px;
}

.bebefit-work-steps .work-step-item h4 {
	margin-bottom: 10px;
}

.bebefit-work-steps .work-step-item:nth-child(1) {
	margin-left: 30px;
	margin-right: 70px;
}

@media only screen and (max-width: 575px) {
	.bebefit-work-steps .work-step-item:nth-child(1) {
		margin-left: 0;
		margin-right: 0;
	}
}

.bebefit-work-steps .work-step-item:nth-child(2) {
	position: absolute;
	top: 50%;
	right: 100%;
	max-width: 470px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	-webkit-transform: translate(80px, -50%);
	-ms-transform: translate(80px, -50%);
	transform: translate(80px, -50%);
}

@media only screen and (max-width: 991px) {
	.bebefit-work-steps .work-step-item:nth-child(2) {
		top: 0;
		right: 0;
		width: auto;
		max-width: none;
		position: relative;
		margin-left: 50px;
		margin-right: 50px;
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
	}
}

@media only screen and (max-width: 991px) and (max-width: 575px) {
	.bebefit-work-steps .work-step-item:nth-child(2) {
		margin-left: 0;
		margin-right: 0;
	}
}

.bebefit-work-steps .work-step-item:nth-child(3) {
	margin-left: 100px;
}

@media only screen and (max-width: 575px) {
	.bebefit-work-steps .work-step-item:nth-child(3) {
		margin-left: 0;
	}
}

.bebefit-work-steps .work-step-item:nth-child(4) {
	margin-left: 50px;
	margin-right: 50px;
}

@media only screen and (max-width: 575px) {
	.bebefit-work-steps .work-step-item:nth-child(4) {
		margin-left: 0;
		margin-right: 0;
	}
}

/*******************************************************/
/**************** 13. Newsletter Section ***************/
/*******************************************************/
.newsletter-video {
	overflow: hidden;
	border-radius: 5px;
	margin-right: -50px;
}

@media only screen and (max-width: 991px) {
	.newsletter-video {
		margin: 0;
	}
}

.newsletter-video:before {
	z-index: 1;
}

.newsletter-video img {
	width: 100%;
}

.newsletter-content {
	margin-right: -70px;
	margin-left: -110px;
	padding: 95px 70px 95px 200px;
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
	.newsletter-content {
		padding-left: 165px;
	}
}

@media only screen and (max-width: 991px) {
	.newsletter-content {
		margin: 0;
		padding: 75px;
	}
}

@media only screen and (max-width: 767px) {
	.newsletter-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.newsletter-content {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
	.newsletter-content h2 {
		font-size: 42px;
	}
}

.newsletter-email {
	z-index: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 20px;
	background: white;
	border-radius: 5px;
	position: relative;
	margin-left: -160px;
}

@media only screen and (max-width: 991px) {
	.newsletter-email {
		margin-left: 0;
	}
}

@media only screen and (max-width: 575px) {
	.newsletter-email {
		display: block;
	}
}

@media only screen and (max-width: 375px) {
	.newsletter-email {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.newsletter-email label {
	top: 44px;
	left: 45px;
	line-height: 1;
	cursor: pointer;
	margin-bottom: 0;
	position: absolute;
	color: #204ecf;
}

@media only screen and (max-width: 375px) {
	.newsletter-email label {
		left: 25px;
	}
}

.newsletter-email input {
	padding: 15px 0 15px 50px;
}

@media only screen and (max-width: 375px) {
	.newsletter-email input {
		padding-left: 40px;
	}
}

.newsletter-email input::-webkit-input-placeholder {
	font-weight: 600;
	color: #262d3d;
}

.newsletter-email input:-ms-input-placeholder {
	font-weight: 600;
	color: #262d3d;
}

.newsletter-email input::-ms-input-placeholder {
	font-weight: 600;
	color: #262d3d;
}

.newsletter-email input::placeholder {
	font-weight: 600;
	color: #262d3d;
}

.video-play {
	left: 50%;
	top: 50%;
	z-index: 3;
	position: absolute;
	width: 80px;
	height: 80px;
	background: white;
	line-height: 80px;
	border-radius: 50%;
	text-align: center;
	color: #204ecf;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.newsletter-radios {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 20px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.newsletter-radios .custom-radio {
	padding-left: 35px;
}

.newsletter-radios .custom-radio:not(:last-child) {
	margin-right: 35px;
}

.newsletter-radios .custom-control-label {
	cursor: pointer;
}

.newsletter-radios .custom-control-label:after {
	top: 6px;
	left: -35px;
	width: 25px;
	height: 25px;
	background: rgba(0, 204, 131, 0.15);
	line-height: 25px;
	border-radius: 50%;
	text-align: center;
}

.newsletter-radios .custom-control-label:before {
	top: 9px;
	z-index: 1;
	left: -28px;
	border: none;
	font-size: 12px;
	content: "\f00c";
	font-weight: 600;
	color: #00cc83;
	background: transparent;
	font-family: "Font Awesome 5 Free";
}

.newsletter-radios .custom-control-input:checked~.custom-control-label::before {
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: transparent;
}

.newsletter-radios .custom-control-input:checked~.custom-control-label::after {
	background-image: none;
	background-color: #00cc83;
}

/* Newsletter Video Section */
.newsletter-video-section {
	padding-left: 55px;
	padding-right: 55px;
}

@media only screen and (max-width: 1500px) {
	.newsletter-video-section {
		padding-left: 0;
		padding-right: 0;
	}
}

.newsletter-video-part {
	height: 720px;
}

@media only screen and (max-width: 767px) {
	.newsletter-video-part {
		height: 500px;
	}
}

@media only screen and (max-width: 575px) {
	.newsletter-video-part {
		height: 400px;
	}
}

.newsletter-video-part:before {
	opacity: 0.45;
}

.newsletter-video-part .video-play {
	color: white;
	background: #00cc83;
}

@media only screen and (min-width: 767px) {
	.newsletter-video-part .video-play {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}
}

.newsletter-video-part .notification {
	left: 16%;
	bottom: 15%;
	max-width: 250px;
	position: absolute;
	padding: 45px 40px 25px;
}

@media only screen and (max-width: 1199px) {
	.newsletter-video-part .notification {
		left: 10%;
		bottom: 10%;
	}
}

@media only screen and (max-width: 767px) {
	.newsletter-video-part .notification {
		display: none;
	}
}

.newsletter-video-part .notification img {
	margin-bottom: 18px;
}

.newsletter-video-part .notification:before {
	content: "";
	left: -40%;
	top: 15%;
	width: 150px;
	height: 70px;
	position: absolute;
	/* background: url(../images/shapes/notification-line.png) no-repeat center/cover; */
}

@media only screen and (max-width: 1199px) {
	.newsletter-video-part .notification:before {
		left: -30%;
	}
}

.newsletter-video-part .bg-text {
	right: 50%;
	bottom: 50%;
	opacity: 0.2;
	font-size: 300px;
	color: transparent;
	text-transform: capitalize;
	-webkit-text-stroke: 1px white;
	-webkit-transform: translate(50%, 50%);
	-ms-transform: translate(50%, 50%);
	transform: translate(50%, 50%);
}

@media only screen and (max-width: 1199px) {
	.newsletter-video-part .bg-text {
		font-size: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.newsletter-video-part .bg-text {
		font-size: 150px;
	}
}

@media only screen and (max-width: 575px) {
	.newsletter-video-part .bg-text {
		font-size: 100px;
	}
}

@media only screen and (max-width: 375px) {
	.newsletter-video-part .bg-text {
		font-size: 80px;
	}
}

.newsletter-video-part .bg-text:before {
	content: "";
	width: 320px;
	height: 320px;
	position: absolute;
	border-radius: 50%;
	border: 1px solid white;
	-webkit-transform: translate(-50%, -25%);
	-ms-transform: translate(-50%, -25%);
	transform: translate(-50%, -25%);
}

@media only screen and (max-width: 1199px) {
	.newsletter-video-part .bg-text:before {
		width: 200px;
		height: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.newsletter-video-part .bg-text:before {
		display: none;
	}
}

.newsletter-video-content {
	z-index: 1;
	position: relative;
	margin-left: -330px;
	padding: 60px 70px 70px;
}

@media only screen and (max-width: 1199px) {
	.newsletter-video-content {
		margin-left: 0;
	}
}

@media only screen and (max-width: 575px) {
	.newsletter-video-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.newsletter-video-content .newsletter-radios .custom-control-label {
		font-size: 18px;
	}
}

.newsletter-video-content .newsletter-radios .custom-control-label:before {
	color: white;
}

.newsletter-video-content .newsletter-email {
	margin-left: 0;
}

.newsletter-video-content .newsletter-email label {
	top: 40px;
}

.newsletter-video-content .newsletter-email label i {
	color: #204ecf;
}

.newsletter-video-content .newsletter-email input {
	padding-top: 10px;
	padding-bottom: 10px;
}

.newsletter-video-content .newsletter-email .theme-btn {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	padding-left: 30px;
	padding-right: 30px;
}

/*******************************************************/
/****************** 14. Event Section ******************/
/*******************************************************/
.event-wrap {
	margin-left: -15px;
	margin-right: -15px;
}

.event-wrap .event-item {
	margin-left: 15px;
	margin-right: 15px;
}

.event-wrap .slick-dots li:before {
	background: white;
}

.event-wrap .slick-dots li.slick-active {
	border-color: white;
}

.event-wrap .slick-dots li.slick-active:before {
	background: white;
}

.event-item {
	padding: 30px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 30px;
	border: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 375px) {
	.event-item {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.event-item .image {
	position: relative;
}

.event-item .image img {
	width: 100%;
	border-radius: 5px;
}

.event-item .image .date {
	position: absolute;
	padding: 2px 20px;
	left: 17%;
	width: 66%;
	bottom: -15px;
	font-size: 15px;
	font-weight: 700;
	text-align: center;
	border-radius: 5px;
	background: #204ecf;
}

.event-item .content {
	padding-top: 50px;
	padding-bottom: 5px;
}

.event-item .content h4 {
	margin-bottom: 22px;
}

@media only screen and (max-width: 375px) {
	.event-item .content h4 {
		font-size: 20px;
	}
}

.event-item .content .location {
	opacity: 0.4;
}

.event-item .content .location i {
	margin-right: 5px;
}

.event-item:hover {
	border-color: white;
}

/* Event Style Two */
.event-item-two {
	margin-bottom: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (max-width: 575px) {
	.event-item-two {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 575px) {
	.event-item-two .content {
		width: 100%;
		margin-top: 25px;
	}
}

.event-item-two .date {
	line-height: 1;
	max-width: 55px;
	font-size: 18px;
	font-weight: 700;
	margin-right: 40px;
	font-family: "Raleway", sans-serif;
}

.event-item-two .date b {
	font-size: 45px;
	margin-bottom: 10px;
	padding-bottom: 20px;
	color: #262d3d;
	display: inline-block;
	border-bottom: 2px solid #e9eaeb;
}

.event-item-two .date span {
	font-size: 20px;
}

.event-item-two .location {
	margin-bottom: 8px;
	display: inline-block;
}

.event-item-two .location i {
	margin-right: 5px;
	color: #00cc83;
}

.event-item-two h4 {
	margin-bottom: 15px;
}

.event-item-two img {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 125px;
	height: 125px;
	border-radius: 50%;
	margin-right: 40px;
}

@media only screen and (max-width: 575px) {
	.event-item-two img {
		margin-right: 0;
	}
}

/* Event Style Three */
.event-section-three .container {
	max-width: 1380px;
}

.event-section-three:after,
.event-section-three:before,
.event-section-three .for-circle:after,
.event-section-three .for-circle:before {
	content: "";
	width: 320px;
	height: 320px;
	z-index: -1;
	border-radius: 50%;
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {

	.event-section-three:after,
	.event-section-three:before,
	.event-section-three .for-circle:after,
	.event-section-three .for-circle:before {
		width: 150px;
		height: 150px;
	}
}

.event-section-three:before {
	left: 3%;
	top: 95%;
}

.event-section-three:after {
	left: 6%;
	top: 105%;
}

.event-section-three .for-circle:before {
	top: 50%;
	right: 8%;
}

.event-section-three .for-circle:after {
	top: 60%;
	right: 3%;
}

.event-three-inner {
	background: white;
	padding: 70px 90px 80px;
	-webkit-box-shadow: 10px 0 60px rgba(175, 175, 175, 0.1);
	box-shadow: 10px 0 60px rgba(175, 175, 175, 0.1);
}

@media only screen and (max-width: 767px) {
	.event-three-inner {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.event-item-three {
	padding: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	margin-bottom: 30px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #e9eaeb;
}

@media only screen and (max-width: 575px) {
	.event-item-three {
		display: block;
	}
}

@media only screen and (max-width: 375px) {
	.event-item-three {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.event-item-three .image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: 150px;
	margin-right: 22px;
	position: relative;
}

@media only screen and (max-width: 575px) {
	.event-item-three .image {
		margin-bottom: 20px;
	}
}

.event-item-three .image .date {
	right: 0;
	bottom: 0;
	color: white;
	font-size: 18px;
	font-weight: 700;
	position: absolute;
	padding: 20px 1px 10px;
	height: calc(100% + 15px);
	background: #204ecf;
	-webkit-writing-mode: vertical-lr;
	-webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0, 50% 10px);
	clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0, 50% 10px);
}

.event-item-three .content .location {
	display: block;
	margin-top: -5px;
	margin-bottom: 5px;
}

.event-item-three .content .location i {
	margin-right: 5px;
	color: #204ecf;
}

.event-item-three .content h4 {
	margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
	.event-item-three .content h4 {
		font-size: 18px;
	}
}

.event-item-three .details-link {
	position: absolute;
	top: -1px;
	right: -1px;
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	color: #262d3d;
	background: #ffb113;
}

/*******************************************************/
/************** 15. Testimonials Section ***************/
/*******************************************************/
.partner-title {
	font-family: "Nunito Sans", sans-serif;
}

.partner-title span {
	color: #204ecf;
}

.partner-iamges-wrap {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.partner-iamges-wrap img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.partner-iamges-wrap img:not(:first-child) {
	margin-left: -30px;
}

.partner-iamges-wrap .plus {
	top: 0;
	right: 0;
	color: white;
	font-size: 25px;
	font-weight: 500;
	position: absolute;
	width: 50px;
	height: 50px;
	background: rgba(0, 204, 131, 0.5);
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
}

.testimonial-wrap {
	margin: -100px;
}

.testimonial-wrap .slick-list {
	padding: 100px;
}

.testimonial-wrap .slick-dots {
	position: absolute;
	top: 140px;
	right: 100px;
	margin-top: 0;
}

@media only screen and (max-width: 575px) {
	.testimonial-wrap .slick-dots {
		top: 55px;
		right: 50%;
		-webkit-transform: translate(50%);
		-ms-transform: translate(50%);
		transform: translate(50%);
	}
}

.testimonial-author {
	margin: 0 0 -110px -100px;
}

@media only screen and (max-width: 991px) {
	.testimonial-author {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.testimonial-author {
		margin-left: 15px;
	}
}

.testimonial-content {
	z-index: 1;
	position: relative;
	padding: 35px 60px;
	-webkit-box-shadow: 10px 0 60px rgba(38, 45, 61, 0.07);
	box-shadow: 10px 0 60px rgba(38, 45, 61, 0.07);
}

@media only screen and (max-width: 575px) {
	.testimonial-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.testimonial-content {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.testimonial-content:after {
	content: "\f10e";
	font-weight: 600;
	line-height: 1;
	top: 40px;
	right: 70px;
	z-index: -1;
	opacity: 0.05;
	font-size: 120px;
	position: absolute;
	font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 991px) {
	.testimonial-content:after {
		top: 50%;
	}
}

.testimonial-content .designation {
	margin-left: 100px;
	margin-bottom: 45px;
}

@media only screen and (max-width: 991px) {
	.testimonial-content .designation {
		background: white;
		border-radius: 5px;
		margin-bottom: 30px;
		padding: 10px 0 10px 20px;
	}
}

@media only screen and (max-width: 480px) {
	.testimonial-content .designation {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.testimonial-content .designation {
		margin-left: 10px;
	}
}

.testimonial-content .designation h4 {
	font-size: 24px;
	margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
	.testimonial-content .designation h4 {
		font-size: 20px;
	}
}

.testimonial-content .designation span {
	font-weight: 600;
	color: #204ecf;
}

.testimonial-content p {
	font-size: 20px;
}

@media only screen and (max-width: 480px) {
	.testimonial-content p {
		font-size: 18px;
	}
}

@media only screen and (max-width: 375px) {
	.testimonial-content p {
		font-size: 16px;
	}
}

.testimonial-content .ratting {
	padding-top: 5px;
}

.testimonial-content .ratting i {
	color: #ff9c00;
	font-size: 14px;
	margin-right: 5px;
}

/* Testimonial Two */
.testimonials-section-two:before {
	position: absolute;
	content: "";
	right: 0;
	width: 70%;
	top: -80px;
	z-index: -1;
	background: #f7f7f7;
	height: calc(100% + 160px);
}

@media only screen and (min-width: 1501px) {
	.testimonials-section-two .container {
		max-width: 1380px;
	}
}

.testimonials-section-two .testimonial-item-two {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 991px) {
	.testimonials-section-two .testimonial-item-two {
		display: block;
	}
}

.testimonials-section-two .testimonial-item-two.slick-active .testimonial-author-two {
	opacity: 1;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}

.testimonial-two-wrap:after {
	position: absolute;
	content: "";
	right: 0;
	top: -50px;
	z-index: -1;
	opacity: 0.05;
	line-height: 1;
	font-size: 300px;
	font-family: "Flaticon";
}

@media only screen and (max-width: 767px) {
	.testimonial-two-wrap:after {
		display: none;
	}
}

.testimonial-two-wrap .slick-dots {
	bottom: 30px;
	position: absolute;
	left: calc(33% + 120px);
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
	.testimonial-two-wrap .slick-dots {
		left: calc(33% + 50px);
	}
}

@media only screen and (max-width: 991px) {
	.testimonial-two-wrap .slick-dots {
		bottom: 0;
		left: auto;
		position: relative;
	}
}

.testimonial-two-wrap .slick-dots li.slick-active {
	border-color: #00cc83;
}

.testimonial-two-wrap .slick-dots li.slick-active:before {
	background: #00cc83;
}

.testimonial-author-two {
	width: 33%;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-right: 120px;
	-webkit-transform: translate(50px);
	-ms-transform: translate(50px);
	transform: translate(50px);
}

@media only screen and (max-width: 1199px) and (min-width: 991px) {
	.testimonial-author-two {
		margin-right: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.testimonial-author-two {
		width: auto;
		margin-right: 0;
		margin-bottom: 35px;
	}
}

.testimonial-content-two {
	padding-bottom: 100px;
	margin-top: -5px;
}

@media only screen and (max-width: 991px) {
	.testimonial-content-two {
		padding-bottom: 20px;
	}
}

.testimonial-content-two .designation {
	margin-bottom: 20px;
}

.testimonial-content-two .designation h3 {
	margin-bottom: 3px;
}

@media only screen and (max-width: 575px) {
	.testimonial-content-two .designation h3 {
		font-size: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.testimonial-content-two .designation h3 {
		font-size: 20px;
	}
}

.testimonial-content-two .designation span {
	font-size: 18px;
	font-weight: 600;
	color: #204ecf;
}

.testimonial-content-two p {
	font-size: 35px;
	line-height: 1.3;
	color: #262d3d;
}

@media only screen and (max-width: 767px) {
	.testimonial-content-two p {
		font-size: 30px;
	}
}

@media only screen and (max-width: 575px) {
	.testimonial-content-two p {
		font-size: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.testimonial-content-two p {
		font-size: 20px;
	}
}

.testimonial-content-two .ratting {
	padding-top: 5px;
}

.testimonial-content-two .ratting i {
	color: #ff9c00;
	font-size: 14px;
	margin-right: 3px;
}

/* Testimonial Three */
.testimonial-three {
	position: relative;
}

.testimonial-three:before {
	content: "";
	width: 100%;
	height: 200%;
	left: 0;
	bottom: 0;
	z-index: -1;
	position: absolute;
	background: #f4f6f9;
}

.testimonial-three-wrap .slick-list {
	overflow: visible;
}

.testimonial-three-wrap .testimonial-three-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 480px) {
	.testimonial-three-wrap .testimonial-three-item {
		display: block;
	}
}

.testimonial-three-wrap .testimonial-three-item .image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	max-width: 100px;
	margin: 25px 25px 25px 0;
	-webkit-transform: translate(30px);
	-ms-transform: translate(30px);
	transform: translate(30px);
}

@media only screen and (max-width: 480px) {
	.testimonial-three-wrap .testimonial-three-item .image {
		margin-top: 0;
	}
}

.testimonial-three-wrap .testimonial-three-item .quality-rating {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 10px;
}

@media only screen and (max-width: 480px) {
	.testimonial-three-wrap .testimonial-three-item .quality-rating {
		margin-left: 25px;
	}
}

.testimonial-three-wrap .testimonial-three-item .quality-rating:before {
	top: -10px;
	left: -25px;
	content: "\f10d";
	font-weight: 600;
	position: absolute;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

.testimonial-three-wrap .testimonial-three-item .quality-rating h4 {
	color: #204ecf;
	margin: 0 15px 5px 0;
}

.testimonial-three-wrap .testimonial-three-item .quality-rating .ratting {
	margin-bottom: 5px;
}

.testimonial-three-wrap .testimonial-three-item .quality-rating .ratting i {
	color: #ffb113;
}

.testimonial-three-wrap .testimonial-three-item .content p {
	font-size: 18px;
	margin-bottom: 15px;
}

.testimonial-three-wrap .testimonial-three-item .content p:after {
	top: 15px;
	left: 10px;
	content: "\f10e";
	font-weight: 600;
	position: relative;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

.testimonial-three-wrap .testimonial-three-item .content h5 {
	margin-bottom: 0;
}

.testimonial-three-wrap .testimonial-three-item .content .designation {
	font-weight: 600;
}

.testimonial-three-wrap .testimonial-three-item.slick-active .image {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}

.testimonial-three-wrap:after {
	top: 50%;
	right: 0;
	z-index: -1;
	opacity: 0.05;
	line-height: 1;
	content: "\f10e";
	font-weight: 600;
	font-size: 200px;
	position: absolute;
	color: #204ecf;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-family: "Font Awesome 5 Free";
}

@media only screen and (max-width: 767px) {
	.testimonial-three-wrap:after {
		font-size: 100px;
	}
}

.testimonial-three-wrap .slick-dots {
	margin-left: 123px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: start;
}

@media only screen and (max-width: 480px) {
	.testimonial-three-wrap .slick-dots {
		margin-left: 0;
	}
}

/* Testimonial Four */
.testimonials-four-iamge {
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
	.testimonials-four-iamge {
		padding-bottom: 0;
	}
}

.testimonial-four-wrap .slick-list {
	padding: 75px 0 30px !important;
}

.testimonial-four-wrap .testimonial-item-four {
	width: 470px;
	opacity: 0.4;
	z-index: 1;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 40px 50px;
	border-radius: 5px;
	position: relative;
	border-radius: 7px;
	background: #f7f7f7;
	-webkit-transform: translate(170px);
	-ms-transform: translate(170px);
	transform: translate(170px);
	-webkit-box-shadow: inset 10px 0 60px rgba(30, 40, 69, 0.07);
	box-shadow: inset 10px 0 60px rgba(30, 40, 69, 0.07);
}

@media only screen and (max-width: 1199px) {
	.testimonial-four-wrap .testimonial-item-four {
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
	}
}

@media only screen and (max-width: 480px) {
	.testimonial-four-wrap .testimonial-item-four {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.testimonial-four-wrap .testimonial-item-four .author-designation {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 22px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.testimonial-four-wrap .testimonial-item-four .author-designation .author {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-right: 20px;
}

.testimonial-four-wrap .testimonial-item-four .author-designation .author img {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}

@media only screen and (max-width: 375px) {
	.testimonial-four-wrap .testimonial-item-four .author-designation .author img {
		width: 50px;
		height: 50px;
	}
}

.testimonial-four-wrap .testimonial-item-four .author-designation .designation h4 {
	font-size: 24px;
	margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
	.testimonial-four-wrap .testimonial-item-four .author-designation .designation h4 {
		font-size: 20px;
	}
}

@media only screen and (max-width: 375px) {
	.testimonial-four-wrap .testimonial-item-four .author-designation .designation h4 {
		font-size: 16px;
	}
}

.testimonial-four-wrap .testimonial-item-four .author-designation .designation span {
	font-weight: 600;
	color: #204ecf;
}

.testimonial-four-wrap .testimonial-item-four p {
	font-size: 20px;
}

@media only screen and (max-width: 480px) {
	.testimonial-four-wrap .testimonial-item-four p {
		font-size: 16px;
	}
}

.testimonial-four-wrap .testimonial-item-four .ratting i {
	font-size: 14px;
	margin-right: 3px;
	color: #ffb113;
}

.testimonial-four-wrap .testimonial-item-four.slick-center {
	opacity: 1;
	z-index: 2;
	width: 570px;
	background: white;
	-webkit-transform: translate(0, -50px);
	-ms-transform: translate(0, -50px);
	transform: translate(0, -50px);
	-webkit-box-shadow: 10px 0 40px rgba(32, 78, 207, 0.15);
	box-shadow: 10px 0 40px rgba(32, 78, 207, 0.15);
}

@media only screen and (max-width: 767px) {
	.testimonial-four-wrap .testimonial-item-four.slick-center {
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
		-webkit-box-shadow: inset 10px 0 40px rgba(32, 78, 207, 0.15);
		box-shadow: inset 10px 0 40px rgba(32, 78, 207, 0.15);
	}
}

.testimonial-four-wrap .testimonial-item-four.slick-center+.testimonial-item-four {
	-webkit-transform: translate(-170px);
	-ms-transform: translate(-170px);
	transform: translate(-170px);
}

@media only screen and (max-width: 1199px) {
	.testimonial-four-wrap .testimonial-item-four.slick-center+.testimonial-item-four {
		-webkit-transform: translate(0);
		-ms-transform: translate(0);
		transform: translate(0);
	}
}

/*******************************************************/
/******************* 16. Blog Section ******************/
/*******************************************************/
.blog-item {
	border-radius: 5px;
	margin-bottom: 30px;
}

.blog-item .blog-content {
	padding: 25px;
}

@media only screen and (max-width: 375px) {
	.blog-item .blog-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.blog-item .blog-content h5,
.blog-item .blog-content h4,
.blog-item .blog-content p {
	margin-bottom: 5px;
}

@media only screen and (max-width: 375px) {

	.blog-item .blog-content h5,
	.blog-item .blog-content h4 {
		font-size: 18px;
	}
}

.blog-item .date {
	color: white;
	min-width: 66px;
	line-height: 1.3;
	font-weight: 700;
	padding: 6px 10px;
	text-align: center;
	border-radius: 5px;
	display: inline-block;
	background: #204ecf;
}

.blog-item .date span {
	display: block;
	font-size: 22px;
}

.blog-item .read-more {
	color: #204ecf;
}

.blog-item .blog-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.blog-item .blog-meta li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.blog-item .blog-meta li i {
	margin-right: 5px;
}

.blog-item .blog-meta li:not(:last-child) {
	margin-right: 20px;
}

.blog-item.big-item {
	z-index: 1;
	position: relative;
}

.blog-item.big-item::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 1;
	background-color: transparent;
}

.blog-item.big-item:before {
	z-index: 1;
	border-radius: 5px;
	background: -webkit-gradient(linear,
			left top,
			left bottom,
			from(transparent),
			to(#0f256e));
	background: -webkit-linear-gradient(transparent, #0f256e);
	background: -o-linear-gradient(transparent, #0f256e);
	background: linear-gradient(transparent, #0f256e);
}

.blog-item.big-item .blog-content {
	bottom: 0;
	left: 10px;
	z-index: 2;
	color: white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: start;
	position: absolute;
	width: calc(100% - 20px);
}

@media only screen and (max-width: 575px) {
	.blog-item.big-item .blog-content {
		left: 0;
		width: 100%;
		display: block;
		position: relative;
		background: #262d3d;
		border-radius: 0 0 5px 5px;
	}
}

.blog-item.big-item .blog-content .date {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-right: 25px;
}

@media only screen and (max-width: 575px) {
	.blog-item.big-item .blog-content .date {
		margin-bottom: 15px;
	}
}

.blog-item.big-item .blog-content a {
	color: white;
}

.blog-item.big-item .blog-content li {
	opacity: 0.5;
}

.blog-item:not(.big-item) .blog-content {
	background: white;
	position: relative;
	padding: 35px 25px 22px;
	-webkit-box-shadow: 10px 0 60px rgba(109, 109, 109, 0.1);
	box-shadow: 10px 0 60px rgba(109, 109, 109, 0.1);
}

@media only screen and (max-width: 375px) {
	.blog-item:not(.big-item) .blog-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.blog-item:not(.big-item) .blog-content .date {
	position: absolute;
	left: 25px;
	bottom: calc(100% - 18px);
}

.blog-item:not(.big-item) .blog-meta {
	margin-bottom: 8px;
}

.blog-item .blog-image img {
	width: 100%;
	border-radius: 5px;
}

.blog-item.style-two .blog-image {
	overflow: hidden;
	position: relative;
}

.blog-item.style-two .blog-image .date {
	bottom: 0;
	right: -1px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	font-size: 18px;
	position: absolute;
	padding-left: 25px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-right: 15px;
	border-radius: 0 0 5px 0;
	background: #00cc83;
	-webkit-clip-path: polygon(100% 0,
			100% 50%,
			100% 100%,
			0% 100%,
			10px 50%,
			0% 0%);
	clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10px 50%, 0% 0%);
}

.blog-item.style-two .blog-image .date span {
	font-size: 30px;
	margin-left: 5px;
}

.blog-item.style-two .blog-content {
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 22px 22px 0;
}

.blog-item.style-two .blog-content h4,
.blog-item.style-two .blog-content p {
	margin-bottom: 15px;
}

@media only screen and (min-width: 767px) {
	.blog-item.style-two.image-left {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.blog-item.style-two.image-left .blog-image {
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
		width: 50%;
		margin-right: 30px;
	}

	.blog-item.style-two.image-left .blog-content {
		padding: 0;
	}
}

.blog-item.style-two.middle-image {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 40px 45px;
	background: #f7f7f7;
}

@media only screen and (max-width: 480px) {
	.blog-item.style-two.middle-image {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.blog-item.style-two.middle-image h4 {
	font-size: 24px;
	margin-bottom: 10px;
}

@media only screen and (max-width: 375px) {
	.blog-item.style-two.middle-image h4 {
		font-size: 20px;
	}
}

.blog-item.style-two.middle-image h4 a:hover {
	color: #204ecf;
	text-decoration: underline;
}

.blog-item.style-two.middle-image .blog-image {
	margin-top: 22px;
	margin-bottom: 22px;
}

.blog-item.style-two.middle-image:hover {
	background: white;
	-webkit-box-shadow: 10px 0 60px rgba(32, 78, 207, 0.1);
	box-shadow: 10px 0 60px rgba(32, 78, 207, 0.1);
}

.blog-section-three .style-two .blog-image .date {
	background: #204ecf;
}

.blog-section-three .style-two .blog-meta li a:hover,
.blog-section-three .style-two .blog-content h4 a:hover {
	color: #204ecf;
}

@media only screen and (min-width: 1501px) {

	.blog-details-wrap,
	.blog-standard-wrap {
		margin-right: 50px;
	}
}

.blog-details-wrap .pagination,
.blog-standard-wrap .pagination {
	padding-top: 50px;
	border-top: 1px solid #e9eaeb;
}

.blog-details-wrap,
.blog-standard-item {
	margin-bottom: 60px;
}

.blog-details-wrap .image,
.blog-standard-item .image {
	margin-bottom: 25px;
	position: relative;
}

.blog-details-wrap .image img,
.blog-standard-item .image img {
	width: 100%;
}

.blog-details-wrap .image a,
.blog-standard-item .image a {
	right: 20px;
	bottom: 20px;
	font-size: 22px;
	position: absolute;
	width: 70px;
	height: 70px;
	background: white;
	line-height: 70px;
	border-radius: 50%;
	text-align: center;
	color: #204ecf;
}

@media only screen and (max-width: 480px) {

	.blog-details-wrap .image a,
	.blog-standard-item .image a {
		width: 50px;
		height: 50px;
		font-size: 16px;
		line-height: 50px;
	}
}

.blog-standard-content {
	padding-left: 20px;
	padding-right: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 375px) {
	.blog-standard-content {
		padding: 0;
	}
}

.blog-standard-content .author {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: 50px;
	margin-top: 5px;
	margin-right: 20px;
}

@media only screen and (max-width: 575px) {
	.blog-standard-content .author {
		display: none;
	}
}

.blog-standard-content h3 {
	margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
	.blog-standard-content h3 {
		font-size: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.blog-standard-content h3 {
		font-size: 22px;
	}
}

@media only screen and (max-width: 375px) {
	.blog-standard-content h3 {
		font-size: 20px;
	}
}

.blog-standard-content .theme-btn {
	margin-top: 15px;
}

.blog-standard-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 10px;
}

.blog-standard-header li {
	font-weight: 600;
}

.blog-standard-header li:not(:last-child) {
	margin-right: 35px;
	padding-right: 35px;
	border-right: 1px solid #e9eaeb;
}

@media only screen and (max-width: 767px) {
	.blog-standard-header li:not(:last-child) {
		margin-right: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width: 480px) {
	.blog-standard-header li:not(:last-child) {
		padding-right: 0;
		border-right: none;
	}
}

.blog-standard-header li i {
	margin-right: 10px;
}

.blog-standard-header li .name {
	font-size: 18px;
	font-weight: 700;
	color: #204ecf;
}

/* Blog Details */
.blog-details-wrap {
	margin-bottom: 0;
}

.blog-details-wrap h4 {
	font-size: 24px;
}

@media only screen and (max-width: 480px) {
	.blog-details-wrap h4 {
		font-size: 20px;
	}
}

@media only screen and (max-width: 375px) {
	.blog-details-wrap h4 {
		font-size: 18px;
	}
}

.blog-details-wrap .title {
	max-width: 600px;
}

@media only screen and (max-width: 767px) {
	.blog-details-wrap .title {
		font-size: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.blog-details-wrap .title {
		font-size: 22px;
	}
}

@media only screen and (max-width: 375px) {
	.blog-details-wrap .title {
		font-size: 20px;
	}
}

.blog-details-wrap p,
.blog-details-wrap h3 {
	margin-bottom: 18px;
}

.blog-details-wrap .tag-share h6 {
	font-size: 18px;
	margin: 5px 15px 0 0;
}

blockquote {
	padding: 35px 50px;
	margin-bottom: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
	background: #f7f7f7;
	border-radius: 0 5px 5px 0;
	border-left: 4px solid #00cc83;
}

@media only screen and (max-width: 480px) {
	blockquote {
		padding-left: 25px;
		padding-right: 25px;
	}
}

blockquote .blockquote-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	font-size: 18px;
	font-weight: 700;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 480px) {
	blockquote .blockquote-footer {
		font-size: 16px;
	}
}

blockquote .blockquote-footer:before {
	font-size: 35px;
	margin-right: 15px;
	color: #204ecf;
}

@media only screen and (max-width: 480px) {
	blockquote .blockquote-footer:before {
		font-size: 25px;
	}
}

.post-nav {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.post-nav>div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 275px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}

.post-nav>div .post-thumb {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: 75px;
	margin-right: 20px;
}

.post-nav>div .post-thumb img {
	border-radius: 5px;
}

.post-nav>div .content h6 {
	font-size: 18px;
	margin-bottom: 5px;
}

.post-nav>div .content span {
	font-size: 14px;
}

.post-nav>div .content span i {
	margin-right: 3px;
}

.comment-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
	.comment-body {
		display: block;
	}
}

.comment-body .author-thumb {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: 85px;
	margin-right: 35px;
}

@media only screen and (max-width: 767px) {
	.comment-body .author-thumb {
		margin-bottom: 15px;
	}
}

.comment-body .author-thumb img {
	width: 100%;
	border-radius: 50%;
}

.comment-body .comment-content {
	padding: 25px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	background: #f7f7f7;
}

.comment-body .comment-content .name-date {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.comment-body .comment-content .name-date h6,
.comment-body .comment-content .name-date .comment-date {
	margin-bottom: 10px;
}

.comment-body .comment-content .name-date h6 {
	font-size: 18px;
}

.comment-body .comment-content .name-date .ratting {
	line-height: 1;
	margin-bottom: 15px;
}

.comment-body .comment-content .name-date .ratting i {
	color: #ffb113;
	margin-right: 2px;
	font-size: 12px;
}

.comment-body .comment-content p {
	margin-bottom: 8px;
}

.comment-body .comment-content .reply-link {
	font-weight: 700;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-family: "Raleway", sans-serif;
}

.comment-body .comment-content .reply-link i {
	margin-left: 6px;
}

.comment-body .comment-content:before {
	width: 0;
	height: 0;
	top: 30px;
	content: "";
	right: 100%;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 20px solid #f7f7f7;
}

@media only screen and (max-width: 767px) {
	.comment-body .comment-content:before {
		display: none;
	}
}

.comment-body:hover .comment-content {
	background: white;
	-webkit-box-shadow: 10px 0 60px rgba(138, 138, 138, 0.2);
	box-shadow: 10px 0 60px rgba(138, 138, 138, 0.2);
}

.comment-body:hover .comment-content:before {
	border-right-color: white;
}

.comment-list>li>.comment-body {
	margin-right: 60px;
}

@media only screen and (max-width: 480px) {
	.comment-list>li>.comment-body {
		margin-right: 25px;
	}
}

.comment-list .children {
	margin-left: 60px;
}

@media only screen and (max-width: 480px) {
	.comment-list .children {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.admin-comment {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.admin-comment .author-thumb {
	max-width: 160px;
	margin-right: 35px;
}

.admin-comment .social-style-two a:first-child {
	margin-left: 0;
}

@media only screen and (max-width: 480px) {
	.comment-form {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.comment-form h4 {
	margin-bottom: 8px;
}

.comment-form .form-group {
	position: relative;
}

.comment-form .form-group>label {
	top: 0;
	right: 0;
	cursor: pointer;
	position: absolute;
}

.comment-form .form-group .nice-select,
.comment-form .form-group .form-control {
	border: none;
	border-radius: 0;
	padding: 0 15px 15px 0;
	background: transparent;
	border-bottom: 2px solid #e9eaeb;
}

.comment-form .form-group .nice-select:focus,
.comment-form .form-group .form-control:focus {
	border-color: #262d3d;
}

.comment-form .form-group .nice-select:after {
	right: 3px;
	width: 8px;
	height: 8px;
	margin-top: -10px;
}

/*******************************************************/
/******************* 17. Client Logo *******************/
/*******************************************************/
.logo-inner {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (max-width: 991px) {
	.logo-inner {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.logo-inner .logo-item {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 50px;
	margin-left: 15px;
	margin-right: 15px;
	-webkit-filter: opacity(0.5) grayscale(1);
	filter: opacity(0.5) grayscale(1);
}

.logo-inner .logo-item:hover {
	-webkit-filter: opacity(1) grayscale(0);
	filter: opacity(1) grayscale(0);
}

@media only screen and (max-width: 767px) {
	.logo-inner .logo-item {
		width: 25%;
		text-align: center;
	}
}

.logo-inner.style-two {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

/*******************************************************/
/**************** 18. Instagram Section ****************/
/*******************************************************/
.instagram-section {
	padding-left: 55px;
	padding-right: 55px;
}

@media only screen and (max-width: 1500px) {
	.instagram-section {
		padding-left: 0;
		padding-right: 0;
	}
}

.instagram-item {
	position: relative;
	margin-bottom: 10px;
}

.instagram-item img {
	width: 100%;
}

.instagram-item .instagram-hover {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	text-align: center;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: rgba(38, 45, 61, 0.45);
}

.instagram-item .instagram-hover a {
	color: white;
	font-size: 18px;
}

.instagram-item .instagram-hover a i {
	display: block;
	font-size: 33px;
	margin-bottom: 5px;
}

.instagram-item:hover .instagram-hover {
	opacity: 1;
}

.footer-instagram:before {
	position: absolute;
	width: 100%;
	height: 55%;
	content: "";
	left: 0;
	bottom: 0;
	z-index: -1;
	background: #141517;
}

.footer-instagram .instagram-hover {
	background: rgba(32, 78, 207, 0.35);
}

/*******************************************************/
/********************* 19. Counter *********************/
/*******************************************************/
.counter-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.counter-wrap.style-two .success-item {
	margin-top: 75px;
}

@media only screen and (max-width: 991px) {
	.counter-wrap.style-two .success-item {
		margin-top: 35px;
	}
}

.counter-wrap.style-two .success-item:first-child {
	margin-top: 0;
}

@media only screen and (max-width: 991px) {
	.counter-wrap.style-two .success-item:first-child {
		margin-top: 35px;
	}
}

.counter-wrap.style-two .count-text {
	color: #00cc83;
}

.success-item {
	margin-top: 30px;
	font-weight: 600;
}

.success-item .count-text {
	display: block;
	line-height: 1;
	font-size: 35px;
	font-weight: 800;
	color: #204ecf;
}

.success-item .count-text.plus:after {
	content: "+";
}

.success-item .count-text.percent:after {
	content: "%";
}

.counter-area {
	z-index: 1;
	position: relative;
}

.counter-area:before {
	position: absolute;
	width: 100%;
	height: 150%;
	content: "";
	left: 0;
	bottom: 0;
	z-index: -1;
	background: #f7f7f7;
}

.counter-area .counter-item {
	padding: 40px;
	background: white;
	margin-bottom: 30px;
}

.counter-area .counter-item span:not(.count-text) {
	font-size: 18px;
	font-weight: 600;
	color: #204ecf;
}

.counter-area .count-text {
	font-size: 45px;
	margin-bottom: 10px;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
}

/* Style Two */
.slider-counter {
	max-width: 570px;
	margin: -120px 10% 0 auto;
}

@media only screen and (max-width: 991px) {
	.slider-counter {
		margin-top: -65px;
		margin-bottom: 45px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-counter {
		margin-left: 15px;
	}
}

.success-item.style-two {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding: 40px 65px;
	background: #204ecf;
}

.success-item.style-two>i,
.success-item.style-two span {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}

.success-item.style-two>i {
	font-size: 40px;
	margin-bottom: 15px;
	display: inline-block;
}

.success-item.style-two span:not(.count-text) {
	font-weight: 600;
}

.success-item.style-two .count-text {
	color: white;
	font-size: 45px;
}

.success-item.style-two:hover {
	background: #ffb113;
}

.success-item.style-two:hover>i,
.success-item.style-two:hover span {
	color: #262d3d;
}

/* Circle Counter */
.counter-number {
	position: relative;
	margin-left: 25px;
	margin-right: 25px;
}

@media only screen and (max-width: 575px) {
	.counter-number {
		margin-right: 0;
	}
}

.counter-number>span {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 20px;
	font-weight: 700;
	font-family: "Raleway", sans-serif;
	-webkit-transform: translate(-50%, -60%);
	-ms-transform: translate(-50%, -60%);
	transform: translate(-50%, -60%);
}

/* Count Home 4 */
.counter-section-three .container {
	max-width: 1380px;
}

.counter-three-wrap {
	z-index: 2;
	padding: 0 35px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: -60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	-webkit-transform: translateY(60px);
	-ms-transform: translateY(60px);
	transform: translateY(60px);
}

@media only screen and (max-width: 375px) {
	.counter-three-wrap {
		padding-left: 0;
		padding-right: 0;
	}
}

.counter-three-wrap .success-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0;
	padding: 35px 45px;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 375px) {
	.counter-three-wrap .success-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.counter-three-wrap .success-item span:not(.count-text) {
	font-weight: 600;
	line-height: 1.55;
}

.counter-three-wrap .success-item .count-text {
	color: white;
	font-size: 45px;
	font-weight: 700;
	margin-right: 20px;
	font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 375px) {
	.counter-three-wrap .success-item .count-text {
		font-size: 35px;
	}
}

.counter-three-wrap .success-item:not(:last-child):after {
	content: "";
	width: 5px;
	height: 5px;
	right: 0;
	top: 50%;
	background: white;
	border-radius: 5px;
	position: absolute;
}

@media only screen and (max-width: 1199px) {
	.counter-three-wrap .success-item:not(:last-child):after {
		display: none;
	}
}

/*******************************************************/
/**************** 20. Categories Section ***************/
/*******************************************************/
.categories-wrap {
	margin-bottom: -30px;
}

.category-item {
	background: white;
	text-align: center;
	padding: 30px 10px;
	border-radius: 5px;
	margin-bottom: 30px;
	-webkit-box-shadow: 10px 0 60px rgba(114, 114, 114, 0.1);
	box-shadow: 10px 0 60px rgba(114, 114, 114, 0.1);
}

@media only screen and (max-width: 767px) {
	.category-item {
		margin-top: 0;
	}
}

.category-item .icon {
	line-height: 1;
	font-size: 50px;
	margin-bottom: 20px;
	color: #00cc83;
}

.category-item h4 {
	margin-bottom: 0;
}

/*******************************************************/
/**************** 21. Advertise Section ****************/
/*******************************************************/
.advertise-item {
	z-index: 1;
	border-radius: 5px;
	position: relative;
	margin-bottom: 30px;
	padding: 32px 45px 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background: rgba(0, 204, 131, 0.1);
}

@media only screen and (max-width: 575px) {
	.advertise-item {
		display: block;
	}
}

@media only screen and (max-width: 375px) {
	.advertise-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.advertise-item.bg-two {
	background: rgba(32, 78, 207, 0.1);
}

.advertise-item .content {
	max-width: 68%;
}

@media only screen and (max-width: 575px) {
	.advertise-item .content {
		max-width: 100%;
	}
}

.advertise-item .image {
	margin: -20px -10px -20px -40px;
	position: relative;
	z-index: -1;
}

@media only screen and (max-width: 575px) {
	.advertise-item .image {
		margin: 25px 0 0;
	}
}

.advertise-item h4 {
	font-size: 24px;
}

.advertise-item .theme-btn {
	font-size: 14px;
	padding: 9px 23px 7px;
}

/*******************************************************/
/****************** 22. Team Members ******************/
/*******************************************************/
.team-section .bg-text {
	right: 50%;
	opacity: 0.015;
	-webkit-transform: translate(50%);
	-ms-transform: translate(50%);
	transform: translate(50%);
	text-transform: capitalize;
}

.team-content p {
	opacity: 0.7;
}

.team-members {
	z-index: 1;
	margin-top: -60px;
	position: relative;
}

@media only screen and (max-width: 991px) {
	.team-members {
		margin-top: 0;
	}
}

.team-members:before {
	content: "";
	top: 60px;
	left: 50%;
	z-index: -1;
	position: absolute;
	width: 850px;
	height: 425px;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	border-radius: 0 0 425px 425px;
	background: rgba(255, 255, 255, 0.03);
}

@media only screen and (max-width: 991px) {
	.team-members:before {
		display: none;
	}
}

.team-member {
	position: relative;
	margin-bottom: 30px;
}

.team-member img {
	width: 100%;
	border-radius: 5px;
}

.team-member .team-over {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 5px;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 25px;
	padding-bottom: 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-transform: scale3d(0, 1, 1);
	transform: scale3d(0, 1, 1);
	background: rgba(32, 78, 207, 0.93);
}

.team-member .team-over h4 {
	margin-top: auto;
	margin-bottom: 0;
}

.team-member .team-over span {
	font-weight: 700;
	margin-bottom: 5px;
}

.team-member:hover .team-over {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

/* Team Style Two */
.team-member-two {
	text-align: center;
	margin-bottom: 55px;
}

.team-member-two .image {
	position: relative;
}

.team-member-two .image img {
	width: 100%;
	border-radius: 5px;
}

.team-member-two .image .social-style-two {
	position: absolute;
	left: 50%;
	opacity: 0;
	bottom: 50px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 5px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	padding: 8px 20px 3px;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	background: #204ecf;
}

.team-member-two .image .social-style-two a {
	color: white;
	opacity: 0.65;
}

.team-member-two .image .social-style-two a:hover {
	opacity: 1;
}

.team-member-two .member-description h4 {
	margin: 25px 0 0;
}

.team-member-two .member-description span {
	font-weight: 700;
	color: #204ecf;
}

.team-member-two:hover .social-style-two {
	bottom: 20px;
	opacity: 1;
}

/* Instructors */
.instructor-item {
	text-align: center;
	position: relative;
	margin-bottom: -15px;
}

.instructor-item .image img {
	width: 100%;
	border-radius: 5px;
}

.instructor-item .member-description {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding-top: 25px;
	background: white;
}

.instructor-item .member-description h4 {
	margin-bottom: 0;
}

.instructor-item .member-description>span {
	font-weight: 700;
	color: #204ecf;
}

.instructor-item .member-description .ratting {
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 5px;
}

.instructor-item .member-description .ratting i {
	color: #ffa200;
	font-size: 13px;
}

.instructor-item .member-description .ratting span {
	font-size: 18px;
	font-weight: 700;
}

.instructor-item .member-description .social-style-two {
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-top: -10px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	border-radius: 5px;
	padding: 8px 20px 3px;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
	background: #204ecf;
}

.instructor-item .member-description .social-style-two a {
	color: white;
	opacity: 0.65;
}

.instructor-item .member-description .social-style-two a:hover {
	opacity: 1;
}

.instructor-item:hover .member-description {
	-webkit-transform: translateY(-75px);
	-ms-transform: translateY(-75px);
	transform: translateY(-75px);
	-webkit-box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
	box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
}

.instructor-item:hover .ratting,
.instructor-item:hover .social-style-two {
	opacity: 1;
}

/* Become An Instructor */
.join-our-team-content .list-style-three {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.join-our-team-content .list-style-three li {
	width: 48%;
}

@media only screen and (max-width: 575px) {
	.join-our-team-content .list-style-three li {
		width: 100%;
	}
}

.join-our-team-image-part {
	max-width: 525px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	margin-bottom: -30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (min-width: 1501px) {
	.join-our-team-image-part {
		margin-left: auto;
	}
}

.join-our-team-image-part img {
	width: 100%;
	margin-bottom: 30px;
}

.join-our-team-image-part .image-one {
	width: calc(54% - 15px);
}

.join-our-team-image-part .image-two {
	width: calc(46% - 15px);
}

.join-our-team-image-part .years-of-experience {
	color: white;
	left: 50px;
	bottom: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	font-size: 20px;
	max-width: 270px;
	position: absolute;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px 40px 30px;
	font-family: "Raleway", sans-serif;
	background: #204ecf;
}

@media only screen and (max-width: 480px) {
	.join-our-team-image-part .years-of-experience {
		left: 15px;
		bottom: 100px;
		max-width: 200px;
		padding: 5px 25px 20px;
	}
}

.join-our-team-image-part .years-of-experience b {
	font-size: 60px;
	margin-right: 15px;
}

@media only screen and (max-width: 480px) {
	.join-our-team-image-part .years-of-experience b {
		font-size: 35px;
	}
}

.join-our-team-image-part .years-of-experience span {
	margin-top: 10px;
	line-height: 30px;
}

@media only screen and (max-width: 480px) {
	.join-our-team-image-part .years-of-experience span {
		font-size: 16px;
		line-height: 26px;
	}
}

.join-our-team-image-part .bg-text {
	top: 20px;
	left: 35px;
	font-size: 100px;
}

/*******************************************************/
/****************** 23. Shop Section *******************/
/*******************************************************/
.shop-shorter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.shop-shorter .sort-text {
	font-size: 18px;
	font-weight: 600;
	margin-right: auto;
	margin-bottom: 5px;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
}

.shop-shorter .sort-text b {
	font-size: 22px;
	font-weight: 700;
	color: #204ecf;
}

.shop-shorter .grid-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 20px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.shop-shorter .grid-list li {
	margin-right: 25px;
}

.shop-shorter .nice-select {
	font-size: 15px;
	font-weight: 800;
	min-width: 200px;
	border-radius: 5px;
	color: #262d3d;
	background: transparent;
	text-transform: uppercase;
	padding: 10px 55px 10px 25px;
	border: 2px solid #e9eaeb;
}

.shop-shorter .nice-select:after {
	width: 7px;
	height: 7px;
	right: 20px;
	border-color: #262d3d;
}

.shop-shorter .nice-select .list {
	width: 100%;
}

.product-item {
	border-radius: 5px;
	position: relative;
	margin-bottom: 30px;
	-webkit-box-shadow: 10px 0 60px rgba(138, 138, 138, 0.15);
	box-shadow: 10px 0 60px rgba(138, 138, 138, 0.15);
}

.product-item .product-image {
	padding: 30px;
	min-height: 265px;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 1;
	position: relative;
}

.product-item .product-image::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0;
	background-color: #204ecf;
}

.product-item .product-image:before {
	z-index: 1;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 5px;
}

.product-item .product-image .off {
	position: absolute;
	top: 10px;
	right: 10px;
	color: white;
	z-index: 2;
	line-height: 1;
	font-size: 15px;
	padding: 5px 10px;
	font-weight: 700;
	border-radius: 5px;
	background: #204ecf;
}

.product-item .product-image .cart-btn {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	opacity: 0;
	color: white;
	font-weight: 700;
	padding: 5px 15px;
	border-radius: 5px;
	display: inline-block;
	text-transform: capitalize;
	background: #00cc83;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.product-item .product-image .cart-btn:hover {
	background: #204ecf;
}

.product-item .product-content {
	padding: 10px 50px 30px;
}

.product-item .product-content h6 {
	font-size: 18px;
	margin-bottom: 5px;
}

.product-item .product-content .category {
	font-size: 15px;
}

.product-item .product-content .ratting i {
	color: #ffa200;
	font-size: 12px;
	margin-right: 2px;
}

.product-item .product-content .price del {
	opacity: 0.5;
	font-size: 13px;
	font-weight: 700;
	margin-right: 5px;
}

.product-item .product-content .price del:before {
	content: "$";
}

.product-item .product-content .price span {
	font-weight: 800;
	color: #00cc83;
}

.product-item .product-content .price span:before {
	content: "$";
}

.product-item:hover .product-image:before {
	opacity: 0.75;
}

.product-item:hover .product-image .cart-btn {
	opacity: 1;
}

/* Product Details */
.preview-images {
	padding: 55px;
	background: #f7f7f7;
}

.preview-images .preview-item.active {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.thumb-images {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.thumb-images a {
	padding: 20px;
	margin-top: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #f7f7f7;
	width: calc(33.333% - 20px);
}

.product-details-content h2 {
	font-size: 42px;
}

@media only screen and (max-width: 767px) {
	.product-details-content h2 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 575px) {
	.product-details-content h2 {
		font-size: 30px;
	}
}

.product-details-content .author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.product-details-content .author img {
	width: 36px;
	height: 36px;
	margin-right: 15px;
	border-radius: 50%;
}

.product-details-content .author h6 {
	font-size: 18px;
	margin-bottom: 0;
}

.product-details-content .ratting-price {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.product-details-content .ratting-price .ratting {
	margin-right: 15px;
}

.product-details-content .ratting-price .ratting i {
	font-size: 12px;
	margin-right: 2px;
	color: #ffb113;
}

.product-details-content .ratting-price .price {
	font-size: 24px;
	font-weight: 800;
	color: #204ecf;
}

.product-details-content .ratting-price .price:before {
	content: "$";
}

.product-details-content .category-tags li {
	margin-bottom: 10px;
}

.product-details-content .category-tags li b {
	font-size: 18px;
	font-weight: 700;
	color: #262d3d;
	font-family: "Raleway", sans-serif;
}

.product-details-content .category-tags li span {
	font-size: 18px;
	font-weight: 700;
	margin-left: 20px;
	margin-right: 20px;
	color: #262d3d;
}

@media only screen and (max-width: 375px) {
	.product-details-content .category-tags li span {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.product-details-content .category-tags li a:not(:last-child):after {
	content: ",";
}

.product-details-content .add-to-cart {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.product-details-content .add-to-cart input {
	width: 80px;
	border-radius: 5px;
	margin-right: 10px;
	padding: 10px 20px;
	margin-bottom: 10px;
	background: transparent;
	border-color: #e9eaeb;
}

.product-details-content .add-to-cart .theme-btn {
	margin-right: 10px;
	margin-bottom: 10px;
}

.product-details-content .add-to-cart .love {
	line-height: 1;
	padding: 10px 20px;
	border-radius: 5px;
	margin-bottom: 10px;
	background: transparent;
	border: 1px solid #e9eaeb;
}

.product-information-tab li:not(:last-child) {
	margin-right: 50px;
}

@media only screen and (max-width: 767px) {
	.product-information-tab li:not(:last-child) {
		margin-right: 15px;
	}
}

.product-information-tab li a {
	color: #afafaf;
	font-size: 18px;
	font-weight: 800;
	padding: 15px 20px;
	margin-bottom: -1px;
	display: inline-block;
	text-transform: uppercase;
	border-bottom: 2px solid transparent;
}

@media only screen and (max-width: 767px) {
	.product-information-tab li a {
		padding: 10px;
	}
}

@media only screen and (max-width: 575px) {
	.product-information-tab li a {
		font-size: 16px;
	}
}

.product-information-tab li a:hover,
.product-information-tab li a.active {
	color: #262d3d;
	border-color: #204ecf;
}

.review-form,
.contact-form {
	-webkit-box-shadow: 10px 0 60px rgba(138, 138, 138, 0.1);
	box-shadow: 10px 0 60px rgba(138, 138, 138, 0.1);
}

@media only screen and (max-width: 575px) {

	.review-form,
	.contact-form {
		padding-left: 22px;
		padding-right: 22px;
	}
}

.review-form h4,
.contact-form h4 {
	font-size: 24px;
}

.review-form .your-ratting,
.contact-form .your-ratting {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 10px;
}

.review-form .your-ratting h6,
.contact-form .your-ratting h6 {
	font-size: 18px;
	margin-bottom: 0;
	margin-right: 20px;
}

.review-form .your-ratting i,
.contact-form .your-ratting i {
	margin-right: 5px;
	color: #ffb113;
}

/*******************************************************/
/***************** 24. Pricing Section *****************/
/*******************************************************/
.pricin-item {
	overflow: hidden;
	background: white;
	position: relative;
	padding: 40px 55px;
	margin-bottom: 30px;
}

.pricin-item .popularity {
	color: white;
	font-weight: 800;
	position: absolute;
	text-transform: uppercase;
	background: #ffa200;
	right: 0;
	opacity: 0;
	top: -120px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	padding: 25px 7px 15px 5px;
	-webkit-writing-mode: vertical-lr;
	-webkit-clip-path: polygon(100% 0,
			100% 50%,
			100% 100%,
			0% 100%,
			0 0,
			50% 10px);
	clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 0, 50% 10px);
}

.pricin-item .icon {
	margin-bottom: 35px;
	width: 125px;
	height: 125px;
	background: #f7f7f7;
	line-height: 125px;
	border-radius: 50%;
	text-align: center;
}

.pricin-item h4 {
	font-size: 24px;
	margin-bottom: 0;
}

.pricin-item .price {
	display: block;
	line-height: 1;
	font-size: 75px;
	font-weight: 600;
	margin-top: 15px;
	color: #00cc83;
}

.pricin-item .price:before {
	content: "$";
	font-size: 24px;
}

.pricin-item .price:after {
	font-size: 18px;
	color: #415674;
	content: "/ Per month";
}

.pricin-item .save {
	font-size: 18px;
	font-weight: 600;
	color: #204ecf;
}

.pricin-item .list-style-two {
	margin-top: 25px;
}

.pricin-item .list-style-two li:before {
	content: "\f058";
}

.pricin-item .theme-btn {
	margin-top: 35px;
	padding-left: 30px;
	padding-right: 30px;
}

.pricin-item.style-two {
	height: 100%;
	margin-bottom: 0;
	padding-left: 40px;
	padding-right: 40px;
	border: 1px solid #e9eaeb;
}

.pricin-item.style-two .list-style-two {
	padding-top: 30px;
	border-top: 1px solid #e9eaeb;
}

@media only screen and (max-width: 480px) {
	.pricin-item.style-two {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 480px) {
	.pricin-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.pricin-item:hover .popularity {
	top: 0;
	opacity: 1;
}

/*******************************************************/
/***************** 25. Gallery Section *****************/
/*******************************************************/
.gallery-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-bottom: 2px solid #e9eaeb;
}

.gallery-filter li {
	color: #979797;
	cursor: pointer;
	font-size: 18px;
	font-weight: 700;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-top: 10px;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: -3px;
	padding: 0 10px 25px;
	font-family: "Raleway", sans-serif;
	border-bottom: 4px solid transparent;
}

@media only screen and (max-width: 991px) {
	.gallery-filter li {
		margin-left: 5px;
		margin-right: 5px;
	}
}

@media only screen and (max-width: 767px) {
	.gallery-filter li {
		font-size: 16px;
		padding-bottom: 10px;
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media only screen and (max-width: 480px) {
	.gallery-filter li {
		font-size: 14px;
	}
}

.gallery-filter li:hover,
.gallery-filter li.current {
	color: #262d3d;
}

.gallery-filter li.current {
	border-color: #00cc83;
}

.gallery-item {
	text-align: center;
	margin-bottom: 30px;
}

.gallery-item .gallery-image {
	position: relative;
}

.gallery-item .gallery-image:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: #204ecf;
}

.gallery-item .gallery-image img {
	width: 100%;
}

.gallery-item .gallery-image a {
	top: 50%;
	left: 50%;
	z-index: 2;
	opacity: 0;
	color: white;
	font-size: 20px;
	position: absolute;
	width: 85px;
	height: 85px;
	background: #00cc83;
	line-height: 85px;
	border-radius: 50%;
	text-align: center;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.gallery-item .gallery-content {
	padding: 25px;
	-webkit-box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
	box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
}

.gallery-item .gallery-content h4 {
	margin-bottom: 0;
}

.gallery-item .gallery-content span {
	font-weight: 600;
}

.gallery-item:hover .gallery-image:before {
	opacity: 0.9;
}

.gallery-item:hover .gallery-image a {
	opacity: 1;
}

/*******************************************************/
/******************* 26. FAQs Section ******************/
/*******************************************************/
.faqs-tab {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 2px solid #e9eaeb;
}

.faqs-tab li:not(:last-child) {
	margin-right: 20px;
}

@media only screen and (max-width: 991px) {
	.faqs-tab li:not(:last-child) {
		margin-right: 10px;
	}
}

.faqs-tab li a {
	color: #979797;
	font-size: 18px;
	font-weight: 700;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	padding-top: 10px;
	margin-bottom: -3px;
	padding: 0 10px 15px;
	display: inline-block;
	font-family: sans-serif;
	border-bottom: 4px solid transparent;
}

@media only screen and (max-width: 991px) {
	.faqs-tab li a {
		padding-bottom: 0;
		padding-left: 0;
		border-width: 2px;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 480px) {
	.faqs-tab li a {
		font-size: 14px;
	}
}

.faqs-tab li a:hover,
.faqs-tab li a.active {
	color: #262d3d;
}

.faqs-tab li a.active {
	border-color: #00cc83;
}

.faqs-tab li:first-child a {
	padding-left: 0;
}

.faq-accordion .card {
	border: none;
	background: transparent;

}

.faq-accordion .card:not(:last-child) {
	margin-bottom: 10px;
}

.faq-accordion .card-header {
	font-size: 20px;
	font-weight: 700;
	border-radius: 0;
	padding: 11px 40px;
	background: transparent;
	color: #204ecf;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-family: cursive;
	border: 1px solid #e9eaeb;
}

@media only screen and (max-width: 480px) {
	.faq-accordion .card-header {
		font-size: 18px;
		line-height: 1.2;
		padding-left: 20px;
		padding-right: 20px;
		font-family: cursive;
	}
}

.faq-accordion .card-header.collapsed {
	text-decoration: none;
	color: #262d3d;
	background: #f7f7f7;
	border-color: #f7f7f7;
}

.faq-accordion .card-header.collapsed .toggle-btn {
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.faq-accordion .card-header.collapsed .toggle-btn:after,
.faq-accordion .card-header.collapsed .toggle-btn:before {
	background: #262d3d;

}

.faq-accordion .card-header.collapsed .toggle-btn:before {
	-webkit-transform: translate(-50%, -50%) rotate(0);
	-ms-transform: translate(-50%, -50%) rotate(0);
	transform: translate(-50%, -50%) rotate(0);
}

.faq-accordion .card-header .toggle-btn {
	height: 15px;
	width: 15px;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-left: 15px;
	position: relative;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.faq-accordion .card-header .toggle-btn:after,
.faq-accordion .card-header .toggle-btn:before {
	position: absolute;
	content: "";
	left: 50%;
	top: 50%;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	background: #204ecf;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.faq-accordion .card-header .toggle-btn:before {
	width: 2px;
	height: 100%;
	-webkit-transform: translate(-50%, -50%) rotate(90deg);
	-ms-transform: translate(-50%, -50%) rotate(90deg);
	transform: translate(-50%, -50%) rotate(90deg);
}

.faq-accordion .card-header .toggle-btn:after {
	height: 2px;
	width: 100%;
}

.faq-accordion .card-body {
	padding: 0;
	border: 1px solid #e9eaeb;
	border-top: none;

}

.tab-content .card {
	background: white;
	margin-bottom: 15px;
	-webkit-box-shadow: inset 10px 0 60px rgba(139, 139, 139, 0.1);
	box-shadow: inset 10px 0 60px rgba(139, 139, 139, 0.1);
}

.tab-content .card-body,
.tab-content .card-header {
	border: none;
	border-radius: 5px;
	padding-left: 30px;
	padding-right: 30px;
}

@media only screen and (max-width: 480px) {

	.tab-content .card-body,
	.tab-content .card-header {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.tab-content .card-body {
	padding-bottom: 5px;
}

.tab-content .card-header {
	font-size: 18px;
	background: transparent;
}

@media only screen and (max-width: 480px) {
	.tab-content .card-header {
		font-size: 16px;
	}
}

.tab-content .card-header.collapsed {
	background: #f4f6fd;
}

/* Video Part */
.video-inner:before {
	z-index: 1;
}

.video-inner .video-play {
	color: white;
	width: 100px;
	height: 100px;
	font-size: 20px;
	line-height: 100px;
	background: #204ecf;
}

@media only screen and (max-width: 480px) {
	.video-inner .video-play {
		width: 60px;
		height: 60px;
		font-size: 16px;
		line-height: 60px;
	}
}

/* FAQs Form */
.faq-contact-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.faq-contact-info .icon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: white;
	margin-top: 3px;
	margin-right: 15px;
	width: 50px;
	height: 50px;
	background: #204ecf;
	line-height: 50px;
	border-radius: 50%;
	text-align: center;
}

.faq-contact-info h6 {
	margin-bottom: 0;
}

.faq-contact-info a {
	font-size: 24px;
}

.faq-form .form-group {
	position: relative;
}

.faq-form .form-group>label {
	top: 0;
	right: 0;
	cursor: pointer;
	position: absolute;
	color: #204ecf;
}

.faq-form .form-group .nice-select,
.faq-form .form-group .form-control {
	border: none;
	border-radius: 0;
	padding: 0 15px 15px 0;
	background: transparent;
	border-bottom: 2px solid #e9eaeb;
}

.faq-form .form-group .nice-select:focus,
.faq-form .form-group .form-control:focus {
	border-color: #262d3d;
}

.faq-form .form-group .nice-select:after {
	right: 3px;
	width: 10px;
	height: 10px;
	margin-top: -10px;
	border-width: 3px;
	border-color: #204ecf;
}

.faq-form .form-btn-label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.faq-form .form-btn-label .theme-btn {
	margin-top: 10px;
	margin-right: 30px;
}

.faq-form .form-btn-label label {
	cursor: pointer;
	margin-top: 10px;
}

/*******************************************************/
/****************** 27. Contact Area *******************/
/*******************************************************/
.contact-info-area .bg-text {
	bottom: 65px;
	font-weight: 800;
}

.contact-info-area:before {
	content: "";
	bottom: 0;
	z-index: -1;
	width: 330px;
	height: 330px;
	left: -150px;
	border-radius: 50%;
	position: absolute;
	background: #f7f7f7;
	-webkit-animation: 5s down-up-one infinite;
	animation: 5s down-up-one infinite;
}

.contact-info-box {
	padding: 35px;
	background: white;
	text-align: center;
	border-radius: 5px;
	-webkit-box-shadow: 10px 0 60px rgba(138, 138, 138, 0.1);
	box-shadow: 10px 0 60px rgba(138, 138, 138, 0.1);
}

.contact-info-box>i {
	color: white;
	font-size: 35px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 25px;
	display: inline-block;
	width: 80px;
	height: 80px;
	background: #00cc83;
	line-height: 80px;
	border-radius: 50%;
	text-align: center;
}

.contact-info-box h4 {
	margin-bottom: 10px;
}

.contact-info-box span {
	font-size: 18px;
	font-weight: 600;
}

.contact-info-box:hover>i {
	background: #204ecf;
}

@media only screen and (min-width: 1501px) {
	.contact-page-image {
		margin-left: 100px;
		margin-right: -150px;
	}
}

.contact-form {
	background: white;
	margin-top: -290px;
	border-radius: 5px;
	-webkit-transform: translateY(290px);
	-ms-transform: translateY(290px);
	transform: translateY(290px);
}

.contact-page-map {
	margin-bottom: -310px;
}

/* 404 Error Page */
.error-inner img {
	margin-left: -120px;
}

@media only screen and (max-width: 767px) {
	.error-inner img {
		margin-left: -50px;
	}
}

@media only screen and (max-width: 480px) {
	.error-inner img {
		margin-left: -25px;
	}
}

@media only screen and (min-width: 1501px) {
	.error-inner .theme-btn {
		padding-left: 70px;
		padding-right: 70px;
	}
}

/*******************************************************/
/***************** 29. Sidebar Widgets *****************/
/*******************************************************/
.widget-title {
	margin-bottom: 20px;
}

.widget {
	border-radius: 3px;
	padding: 35px 33px;
	border: 1px solid #e9eaeb;
}

.widget:not(:last-child) {
	margin-bottom: 30px;
}

@media only screen and (max-width: 375px) {
	.widget {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.widget-search {
	border: none;
	font-size: 18px;
	font-weight: 600;
	padding-top: 18px;
	padding-bottom: 18px;
	background: #f7f7f7;
	font-family: "Raleway", sans-serif;
}

.widget-search form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.widget-search form input {
	border: none;
	padding: 5px 0 0;
	background: transparent;
}

.widget-search form input::-webkit-input-placeholder {
	font-size: 18px;
}

.widget-search form input:-ms-input-placeholder {
	font-size: 18px;
}

.widget-search form input::-ms-input-placeholder {
	font-size: 18px;
}

.widget-search form input::placeholder {
	font-size: 18px;
}

.widget-search form button {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	color: #00cc83;
	background: transparent;
}

.widget-menu ul li {
	font-weight: 600;
}

.widget-menu ul li:not(:last-child) {
	margin-bottom: 10px;
}

.widget-menu ul li:before {

	font-size: 14px;
	margin-right: 8px;
	font-family: "Font Awesome 5 Free";
}

.widget-radio .custom-control {
	font-weight: 600;
	padding-left: 30px;
}

.widget-radio .custom-control:not(:last-child) {
	margin-bottom: 10px;
}

.widget-radio .custom-control-label {
	cursor: pointer;
}

.widget-radio .custom-control-label::after {
	width: 18px;
	height: 18px;
	left: -30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	border-radius: 50%;
	border: 1px solid #e9eaeb;
}

.widget-radio .custom-control-label:before {
	opacity: 0;
	width: 18px;
	height: 18px;
	left: -30px;
	font-size: 8px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	content: "\f00c";
	line-height: 17px;
	text-align: center;
	font-family: "Font Awesome 5 Free";
}

.widget-radio .custom-control-input:checked~.custom-control-label::before {
	opacity: 1;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: none;
	border-color: #00cc83;
	background-color: #00cc83;
}

.widget-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: none;
	border-color: #00cc83;
}

.widget-ratting ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	cursor: pointer;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.widget-ratting ul li:not(:last-child) {
	margin-bottom: 6px;
}

.widget-ratting ul li i {
	color: #bbbbbb;
	font-size: 13px;
	margin-right: 1px;
}

.widget-ratting ul li i.checked {
	color: #ffa200;
}

.widget-ratting ul li span {
	font-weight: 600;
	margin-left: 5px;
}

.widget-course-details {
	padding: 35px;
	border: none;
	max-width: 500px;
	-webkit-box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
	box-shadow: 10px 0 60px rgba(139, 139, 139, 0.1);
}

@media only screen and (max-width: 375px) {
	.widget-course-details {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.widget-course-details .widget-video {
	position: relative;
	margin: -20px -20px 0;
}

.widget-course-details .widget-video img {
	width: 100%;
}

.widget-course-details .price-off {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 30px;
}

.widget-course-details .price-off .price {
	font-size: 30px;
	font-weight: 800;
	margin-right: 15px;
	color: #007dc6;
}

.widget-course-details .price-off .price:before {
	content: "£";
}

.widget-course-details .price-off .off {
	color: white;
	font-size: 13px;
	font-weight: 600;
	border-radius: 13px;
	padding: 8px 13px 5px;
	text-transform: uppercase;
	background: #007dc6;
}

.widget-course-details .theme-btn {
	width: 100%;
	margin-right: 22px;
	color: white;
	text-decoration: none;
}

.widget-course-details .social-style-two {
	margin-top: 20px;
}

.widget-course-details .social-style-two a {
	font-size: 20px;
	color: #204ecf;
}

.youtube-video-play {
	color: white;
	left: 50%;
	top: 50%;
	font-size: 13px;
	width: 55px;
	height: 40px;
	line-height: 40px;
	border-radius: 5px;
	background: #ff0000;
	position: absolute;
	text-align: center;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.course-details-list li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e9eaeb;
}

.course-details-list li:first-child {
	border-top: 1px solid #e9eaeb;
}

.course-details-list li i {
	margin-right: 10px;
	color: #204ecf;
}

.course-details-list li span {
	font-weight: 600;
	margin-right: auto;
	padding-right: 10px;
}

.course-details-list li b {
	color: #262d3d;
}

.widget-recent-courses ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.widget-recent-courses ul li:not(:last-child) {
	margin-bottom: 25px;
}

.widget-recent-courses ul li .image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	max-width: 85px;
	margin-right: 25px;
}

.widget-recent-courses ul li h6 {
	font-size: 17px;
	margin-bottom: 0;
}

.widget-recent-courses ul li .content span {
	font-size: 13px;
	font-family: "Raleway", sans-serif;
}

.widget-recent-courses ul li .content span a {
	font-weight: 700;
}

.widget-about {
	text-align: center;
}

.widget-about .image {
	margin-bottom: 30px;
}

.widget-about .image img {
	max-width: 80%;
	border-radius: 50%;
}

.widget-about h4 {
	font-size: 24px;
}

.widget-about .social-style-two {
	width: 90%;
	margin-top: 20px;
	padding-top: 25px;
	border-top: 1px solid #e9eaeb;
}

.widget-about .social-style-two a:hover {
	color: #204ecf;
}

.tag-coulds {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-bottom: -10px;
}

.tag-coulds a {
	font-weight: 700;
	padding: 1px 15px;
	border-radius: 5px;
	background: #e8edfa;
	margin: 0 10px 10px 0;
}

.tag-coulds a:hover {
	color: white;
	background: #00cc83;
}

.widget-products ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.widget-products ul li:not(:last-child) {
	margin-bottom: 30px;
}

.widget-products ul li .image {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 70px;
	height: 70px;
	padding: 10px;
	margin-right: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #f7f7f7;
}

.widget-products ul li .content h6 {
	margin-bottom: 0;
}

.widget-products ul li .content>span {
	font-size: 14px;
}

.widget-products ul li .content .ratting {
	line-height: 1;
}

.widget-products ul li .content .ratting i {
	font-size: 12px;
	margin-right: 1px;
	color: #ffb113;
}

.newsletter-widget form h6 {
	font-size: 18px;
	font-weight: 600;
	line-height: 1.65;
	margin-bottom: 20px;
}

.newsletter-widget form .email-input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: white;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 10px;
	border-radius: 5px;
	padding: 7px 0 6px 20px;
}

.newsletter-widget form .email-input label {
	cursor: pointer;
	margin: 2px 10px 0 0;
}

.newsletter-widget form .email-input label i {
	color: #204ecf;
}

.newsletter-widget form .email-input input {
	padding: 0;
	border: none;
	font-size: 16px;
	color: #415674;
}

.newsletter-widget form .theme-btn {
	padding: 8px 16px 5px;
}

.widget-price-filter .ui-widget.ui-widget-content {
	height: 3px;
	border: none;
	cursor: pointer;
	margin-top: 35px;
	margin-bottom: 20px;
	background: #e7e8eb;
}

.widget-price-filter .ui-widget-header {
	background: #00cc83;
}

.widget-price-filter .ui-state-default {
	border: none;
	top: -6px;
	margin-left: 0;
	cursor: pointer;
	width: 3px;
	height: 15px;
	background: #00cc83;
}

.widget-price-filter .price,
.widget-price-filter #price {
	font-size: 18px;
	font-weight: 800;
	color: #262d3d;
	font-family: "Nunito Sans", sans-serif;
}

.widget-price-filter .price {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.widget-price-filter .price span:before {
	content: "$";
}

.widget-price-filter #price {
	padding: 0;
	width: 44px;
	border: none;
	background: transparent;
}

.course-sidebar .widget:not(:last-child) {
	margin-bottom: 40px;
}

.blog-sidebar .widget-title {
	padding: 0 33px 18px;
	margin: -18px -33px 30px;
	border-bottom: 1px solid #00cc83;
}

@media only screen and (max-width: 375px) {
	.blog-sidebar .widget-title {
		padding-left: 25px;
		padding-right: 25px;
		margin-left: -25px;
		margin-right: -25px;
	}
}

.shop-sidebar .widget-title {
	margin-bottom: 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e9eaeb;
}

.shop-sidebar .widget-title:before {
	font-size: 18px;
	content: "\f192";
	font-weight: 400;
	margin-right: 15px;
	color: #204ecf;
	font-family: "Font Awesome 5 Free";
}

.shop-sidebar .widget {
	padding: 0;
	border: none;
}

.shop-sidebar .widget:not(:last-child) {
	margin-bottom: 40px;
}

.shop-sidebar .widget-search {
	padding: 10px 25px;
}

.shop-sidebar .widget-menu ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.shop-sidebar .widget-menu ul li:before {
	display: none;
}

.shop-sidebar .widget-menu ul li span {
	margin-left: 8px;
}

.shop-sidebar .tag-coulds a {
	font-size: 15px;
}

.shop-sidebar .tag-coulds a:hover {
	background: #204ecf;
}

/*******************************************************/
/******************* 29. Main Slider *******************/
/*******************************************************/
.slider-section {
	position: relative;
}

.slider-section .pagingInfo {
	top: 50%;
	right: 16%;
	color: white;
	font-size: 24px;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	font-family: "Raleway", sans-serif;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 150px;
	height: 150px;
	background: transparent;
	line-height: 150px;
	border-radius: 50%;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, 0.15);
}

@media only screen and (max-width: 1199px) {
	.slider-section .pagingInfo {
		right: 25px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-section .pagingInfo {
		right: 5px;
		width: 75px;
		height: 75px;
		font-size: 16px;
		background: rgba(0, 0, 0, 0.75);
	}
}

.slider-section .pagingInfo .pagingStatus {
	line-height: 1;
	font-size: 50px;
	margin-right: -10px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
	.slider-section .pagingInfo .pagingStatus {
		font-size: 22px;
		margin-bottom: 20px;
	}
}

.slider-section .pagingInfo .separator {
	line-height: 1;
	margin-top: 30px;
}

@media only screen and (max-width: 767px) {
	.slider-section .pagingInfo .separator {
		margin-top: 20px;
	}
}

.slider-section .pagingInfo .pagingCount {
	line-height: 1;
	margin-top: 30px;
}

@media only screen and (max-width: 767px) {
	.slider-section .pagingInfo .pagingCount {
		margin-top: 20px;
	}
}

.slider-section .bg-text {
	z-index: 1;
	opacity: 0.1;
	font-size: 250px;
	color: transparent;
	-webkit-text-stroke: 1px;
	text-transform: capitalize;
	-webkit-text-stroke-color: white;
}

@media only screen and (max-width: 1500px) {
	.slider-section .bg-text {
		font-size: 200px;
	}
}

@media only screen and (max-width: 1199px) {
	.slider-section .bg-text {
		font-size: 150px;
	}
}

@media only screen and (max-width: 991px) {
	.slider-section .bg-text {
		font-size: 100px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-section .bg-text {
		font-size: 80px;
	}
}

@media only screen and (max-width: 480px) {
	.slider-section .bg-text {
		font-size: 50px;
	}
}

.slider-section .circle-one,
.slider-section .circle-two {
	z-index: 1;
}

.slider-section .circle-one {
	top: 60%;
	left: 65%;
}

.main-slider .slider-item {
	padding-top: 95px;
	min-height: 815px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 1;
	position: relative;
	background-size: cover;
	background-position: center;
}

.main-slider .slider-item::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.7;
	background-color: #141517;
}

@media only screen and (max-width: 991px) {
	.main-slider .slider-item {
		padding-top: 65px;
		min-height: 700px;
	}
}

.main-slider .slider-content {
	z-index: 2;
	padding: 60px;
	max-width: 650px;
	background: white;
	margin-right: 175px;
	position: relative;
	margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
	.main-slider .slider-content {
		margin-right: 0;
	}
}

@media only screen and (max-width: 575px) {
	.main-slider .slider-content {
		padding-left: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.main-slider .slider-content {
		padding-right: 25px;
	}
}

.main-slider .slider-content h2 {
	font-size: 58px;
}

@media only screen and (max-width: 991px) {
	.main-slider .slider-content h2 {
		font-size: 45px;
	}
}

@media only screen and (max-width: 575px) {
	.main-slider .slider-content h2 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.main-slider .slider-content h2 {
		font-size: 35px;
	}
}

@media only screen and (max-width: 375px) {
	.main-slider .slider-content h2 {
		font-size: 30px;
	}
}

.main-slider .slider-content .slider-btns {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-slider .slider-content .slider-btns .theme-btn {
	margin-top: 15px;
	margin-right: 20px;
}

.main-slider .slider-content .slider-btns .slider-video-play {
	color: white;
	margin-top: 15px;
	width: 55px;
	height: 55px;
	background: #204ecf;
	line-height: 55px;
	border-radius: 50%;
	text-align: center;
}

.main-slider .slider-content .slider-btns .slider-video-play:hover {
	background: #ffb113;
}

.main-slider .slider-content * {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.slick-active * {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.slick-active .sub-title-three {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.slick-active h2 {
	-webkit-animation-delay: 0.65s;
	animation-delay: 0.65s;
}

.slick-active .slider-btns {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}

/*******************************************************/
/******************* 30. CTA Section *******************/
/*******************************************************/
.call-to-action-inner {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	background: white;
	margin-top: -65px;
	padding: 20px 50px;
	border-radius: 8px;
	-webkit-transform: translateY(65px);
	-ms-transform: translateY(65px);
	transform: translateY(65px);
	-webkit-box-shadow: 10px 0 60px rgba(36, 103, 236, 0.1);
	box-shadow: 10px 0 60px rgba(36, 103, 236, 0.1);
}

@media only screen and (max-width: 991px) {
	.call-to-action-inner {
		padding-top: 35px;
		padding-bottom: 35px;
	}
}

@media only screen and (max-width: 375px) {
	.call-to-action-inner {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.call-to-action-inner .notification {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 480px) {
	.call-to-action-inner .notification {
		display: block;
	}
}

.call-to-action-inner .notification img {
	margin-right: 30px;
}

@media only screen and (max-width: 480px) {
	.call-to-action-inner .notification img {
		margin-bottom: 10px;
	}
}

.call-to-action-inner .notification h4 {
	font-size: 24px;
	margin-bottom: 0;
}

.call-to-action-inner .notification p {
	margin-bottom: 0;
}

/*******************************************************/
/**************** 31. Why Choose Section ***************/
/*******************************************************/
.why-choose-content {
	max-width: 500px;
}

.why-choose-images {
	z-index: 1;
	max-width: 525px;
	position: relative;
	padding-right: 50px;
	margin-right: -50px;
	padding-bottom: 15px;
}

@media only screen and (max-width: 1500px) {
	.why-choose-images {
		margin-right: 0;
		margin-left: 12%;
	}
}

.why-choose-images .circle,
.why-choose-images .why-choose-three {
	position: absolute;
}

.why-choose-images .circle {
	top: -12%;
	left: -18%;
	width: 42%;
}

.why-choose-images .why-choose-one {
	width: 67%;
	display: block;
	margin-left: auto;
	margin-bottom: -25%;
}

.why-choose-images .why-choose-two {
	width: 67.5%;
}

.why-choose-images .why-choose-three {
	right: 0;
	bottom: 0;
	width: 35%;
}

.why-choose-images:before {
	content: "";
	width: 355px;
	height: 355px;
	opacity: 0.07;
	bottom: 10%;
	right: -15%;
	z-index: -1;
	position: absolute;
	border-radius: 50%;
	background: #204ecf;
}

@media only screen and (max-width: 480px) {
	.why-choose-images:before {
		right: 0;
		width: 200px;
		height: 200px;
	}
}

/*******************************************************/
/************** 32. Cart Checkout Section **************/
/*******************************************************/
/* Checkout Page */
@media only screen and (max-width: 375px) {
	.from-title {
		font-size: 25px;
	}
}

.package-summary table {
	width: 100%;
}

.package-summary tbody {
	background: #f7f7f7;
}

.package-summary tbody tr:not(:last-child) {
	border-bottom: 1px solid #e9eaeb;
}

.package-summary tbody tr td {
	padding: 10px 25px;
}

@media only screen and (max-width: 375px) {
	.package-summary tbody tr td {
		padding-left: 22px;
		padding-right: 22px;
	}
}

/* Cart Page */
.total-cart-price {
	padding: 20px 25px 6px;
	background: #f7f7f7;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (max-width: 480px) {
	.total-cart-price {
		padding-left: 15px;
		padding-right: 15px;
	}

	.total-cart-price h5 {
		font-size: 16px;
	}
}

.cart-item-wrap .alert {
	margin-bottom: 0;
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid #e9eaeb;
}

@media only screen and (max-width: 767px) {
	.cart-item-wrap .alert {
		display: block;
		text-align: center;
	}
}

.cart-item-wrap .alert .product-name {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 165px;
	margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
	.cart-item-wrap .alert .product-name {
		width: 100%;
		margin-bottom: 15px;
	}
}

.cart-item-wrap .alert .avilable i {
	font-size: 14px;
	margin-right: 2px;
	color: #00cc83;
}

.cart-item-wrap .alert .product-total-price {
	font-weight: 800;
	color: #262d3d;
}

@media only screen and (max-width: 767px) {
	.cart-item-wrap .alert .product-total-price {
		display: block;
		margin-top: 15px;
	}
}

.cart-item-wrap .alert .quantity-input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 120px;
}

@media only screen and (max-width: 767px) {
	.cart-item-wrap .alert .quantity-input {
		margin: 15px auto;
	}
}

.cart-item-wrap .alert .quantity-input button {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 33px;
}

.cart-item-wrap .alert .quantity-input input {
	padding: 5px 10px;
	text-align: center;
}

@media only screen and (max-width: 767px) {
	.cart-item-wrap .alert .close {
		top: 10px;
		right: 15px;
		position: absolute;
	}
}

/*******************************************************/
/******************* 33. Main Footer *******************/
/*******************************************************/
.footer-title {
	margin-bottom: 25px;
}

.footer-widget {
	margin-bottom: 50px;
}

.footer-widget p {
	color: #d8d8d8;
}

.menu-widget ul li {
	text-transform: capitalize;
}

.menu-widget ul li a {
	color: #d8d8d8;
	position: relative;
	padding-left: 20px;
}

.menu-widget ul li a:before {
	position: absolute;
	content: "";
	width: 5px;
	height: 5px;
	left: 0;
	top: 8px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	border-radius: 50%;
	background: #d8d8d8;
}

.menu-widget ul li a:hover {
	color: white;
}

.menu-widget ul li a:hover:before {
	background: white;
}

.menu-widget ul li:not(:last-child) {
	margin-bottom: 10px;
}

.contact-info-widget ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.contact-info-widget ul li:not(:last-child) {
	margin-bottom: 10px;
}

.contact-info-widget ul li i {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 28px;
	font-size: 18px;
	margin-top: 6px;
	margin-right: 5px;
	color: #00cc83;
}

.video-widget {
	max-width: 300px;
}

.video-widget img {
	width: 100%;
	border-radius: 8px;
}

.video-widget .video-play {
	width: 45px;
	height: 45px;
	font-size: 14px;
	line-height: 45px;
}

.video-widget .video-play i {
	padding-left: 3px;
	color: #00cc83;
}

.copyright-area {
	margin-top: 25px;
}

.copyright-inner {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-top: 20px;
	padding-bottom: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.copyright-inner p {
	margin-right: 25px;
	margin-bottom: 6px;
}

.copyright-inner p a {
	text-transform: uppercase;
}

.copyright-inner .footer-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	text-transform: capitalize;
}

.copyright-inner .footer-menu li {
	line-height: 1;
	margin-bottom: 8px;
}

.copyright-inner .footer-menu li a {
	color: #d8d8d8;
}

.copyright-inner .footer-menu li a:hover {
	color: white;
	text-decoration: underline;
}

.copyright-inner .footer-menu li:not(:last-child) {
	margin-right: 25px;
}

/* Newsletter */
.footer-newsletter {
	margin-top: 300px;
	margin-bottom: -300px;
	-webkit-transform: translateY(-300px);
	-ms-transform: translateY(-300px);
	transform: translateY(-300px);
}

.footer-newsletter .newsletter-video {
	height: 100%;
	margin-right: 0;
	min-height: 400px;
	border-radius: 10px 0 0 10px;
}

@media only screen and (max-width: 991px) {
	.footer-newsletter .newsletter-video {
		border-radius: 10px 10px 0 0;
	}
}

.footer-newsletter .newsletter-content {
	margin: 0;
	margin-left: -30px;
	padding: 60px 60px 70px;
}

@media only screen and (max-width: 991px) {
	.footer-newsletter .newsletter-content {
		margin-left: 0;
	}
}

@media only screen and (max-width: 480px) {
	.footer-newsletter .newsletter-content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: 375px) {
	.footer-newsletter .newsletter-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.footer-newsletter .newsletter-email {
	margin-left: 0;
	padding: 15px;
}

.footer-newsletter .newsletter-email label {
	top: 38px;
	left: 40px;
}

.footer-newsletter .newsletter-email input {
	padding-left: 50px;
}

.footer-newsletter .theme-btn {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	padding-left: 20px;
	padding-right: 20px;
}

/* Gallery Widget */
@media only screen and (min-width: 1501px) {
	.gallery-widget {
		margin-left: -55px;
	}
}

.gallery-widget-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-bottom: -10px;
}

.gallery-widget-wrap .gallery-widget-item {
	position: relative;
	margin: 0 10px 10px 0;
	max-width: calc(33.333% - 10px);
}

.gallery-widget-wrap .gallery-widget-item a {
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	top: 0;
	color: white;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: rgba(32, 78, 207, 0.5);
}

.gallery-widget-wrap .gallery-widget-item:hover a {
	opacity: 1;
}

/* Scroll To Top */
.bg-black .scroll-top {
	border-color: #141517;
	background: #ffb113;
}

.bg-black .scroll-top span {
	color: #141517;
}

.bg-black .contact-info-widget ul li a:hover {
	color: #204ecf;
}

.bg-black .contact-info-widget ul li i {
	color: #ffb113;
}

/* Footer Two */
.footer-two {
	background: #1e2845;
}

.footer-two .logo-inner {
	border-bottom: 1px solid #353e58;
}

.footer-two .copyright-inner {
	border-top: 1px solid #353e58;
}

.footer-two .scroll-top {
	border-color: #00cc83;
}


/*responsive**/
/* For screens smaller than 640px (mobile devices) */
/* For screens smaller than 640px (mobile devices) */


/* For screens with a width of 1300px or larger */