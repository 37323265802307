/* Styles for small devices */
@media (max-width: 576px) {
    .splide {
      height: 20rem;
    }
  
    .splide__slide img {
      padding: 2rem;
      border-radius: 36px;
      width: 77%;
      height: 79%;
      object-fit: cover;
      object-position: center;
      margin-top: 59px;
      margin-left: 43px;
    }
  }
  
  /* Styles for medium devices */
  @media (min-width: 577px) and (max-width: 768px) {
    .splide {
      height: 25rem;
    }
  
    .splide__slide img {
      padding: 4rem;
      border-radius: 120px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 0;
    }
  }
  
  /* Styles for large devices */
  @media (min-width: 769px) and (max-width: 992px) {
    .splide {
      height: 30rem;
    }
  
    .splide__slide img {
      padding: 6rem;
      border-radius: 150px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 0;
    }
  }
  
  /* Styles for extra-large devices (no changes) */
  @media (min-width: 993px) {
    .splide {
      height: 35rem;
      margin-bottom: 5rem;
      margin-top: -5rem;
    }
  
    .splide__slide img {
      padding: 10rem;
      border-radius: 180px;
      width: 103%;
      height: 64%;
      object-fit: cover;
      object-position: center;
      margin-top: 0;
    }
  }